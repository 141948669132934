.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-default {
  cursor: default;
}

.u-no-text-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.u-toast-error {
  border-radius: 4px;
  background-color: #aa2525;
  height: 50px !important;
  min-height: 50px !important;
  width: 288px;
  color: #fff;
  padding: 0 18px;
}

.u-toast-success {
  background-color: #0c2c52;
  border-radius: 4px;
  height: 50px !important;
  min-height: 50px !important;
  width: 288px;
  color: #fff;
  padding: 0 18px;
}

.u-toast-success-bar {
  opacity: 0 !important;
}

.u-toast-error-bar {
  opacity: 0 !important;
}

@keyframes zoomIn {
  from {
    transform: translateX(3+00px);
    opacity: 0;
  }

  50% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.Tooltip__content {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
