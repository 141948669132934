.NavigationItem {
  margin-top: 17px;
  height: 75px;
  position: relative;
}

.NavigationItem__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NavigationItem__isActive > .NavigationItem__logo > svg > path {
  color: #4092d7;
}

.NavigationItem__isActive > .NavigationItem__title {
  color: #4092d7;
}

.NavigationItem__active-bar {
  width: 4px;
  height: 75px;
  background-color: #4092d7;
  position: absolute;
  left: 0;
}

.NavigationItem__logo {
  position: relative;
  height: 33px;
  color: #fff;
}

.NavigationItem__logo > svg > path {
  color: #a1b4c4;
}

.NavigationItem__title {
  text-align: center;
  color: #a1b4c4;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: -0.1px;
  text-transform: uppercase;
}

.NavigationItem-active {
}

.NavigationItem__amount {
  position: absolute;
  top: 11px;
  right: 22px;
  z-index: 2;
  background-color: #d69d23;
  border-radius: 3px;
  padding-inline: 0.2em;
  width: fit-content;  
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavigationItem__amount-text {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
}
