.Navigation {
  position: fixed;
  height: 100vh;
  width: 90px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  z-index: 550;
}

.Navigation__items {
  padding-top: 141px;
}

.Navigation__item {
  padding: 10px 20px;
  font-size: 20px;
  background-color: transparent;
}

.Navigation__item:hover {
  background-color: #bada55;
  transition: background-color 0.4s linear;
  cursor: pointer;
}

.Navigation__logo {
  position: absolute;
  bottom: 10%;
  right: 50%;
  transform: translateX(50%);
}
