.Scanner {
  width: auto;
  position: absolute;
  background-color: #fff;
  z-index: 666;
}

.Scanner__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 667;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ScannerElement > video {
  object-fit: cover;
  border-radius: 1px;
  border: 1px solid #AFAFAF;
}

.Scanner__detectedFlash {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #68CEA2;
  opacity: 0.7;
}