.Scan {
  max-height: calc(100vh - 150px);
  position: relative;
  margin: 0 0 0 38px;
}

.Scan__title {
  position: absolute;
  top: -57px;
  left: 0;
  width: 100%;
  margin-left: 38px;
}

.Scan__title-text {
  font-size: 25px;
  color: #0c2c52;
  font-weight: 300;
  width: 100%;
}

.Scan__carousel {
  /* border: 1px solid #000; */
  height: calc(100vh - 150px);
}

.Scan__Card {
  border-radius: 10px;
  height: 100%;
  opacity: 0.4;
  transition: all 250ms ease-in-out;
}

.Scan__Card:hover {
  opacity: 1;
  transition: all 250ms ease-in-out;
}

.Scan__Card:focus {
  outline: none;
}

.Scan__Card-image > img {
  width: 100%;
}

.Scan__slider {
  height: 100%;
}

.Scan__ScanMe {
  height: 100%;
  width: 360px;
  display: inline-block;
  opacity: 0.3;
  transform: rotateY(-90deg);
}

.Scan__Slider {
  display: inline-block;
}

.Scan__camera {
  position: absolute;
  top: -12px;
  right: 310px;
  z-index: 16;
  border: 1px solid transparent;
  display: flex;
}

.Scan__camera:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  transition: all 0.1s ease-in-out;
}

.Scan__camera-divider {
  height: 42px;
  border-right: 1px solid #a1b4c4;
  position: absolute;
  top: -10px;
  right: 300px;
  z-index: 4;
}

/* Slick Specific stuff */

.slick-list {
  padding: 18px 0 18px 8px !important;
  height: 100% !important;
  overflow: unset !important;
}

.slick-track {
  height: 100% !important;
  margin-left: 10px !important;
}

.slick-slide {
  display: none;
  float: left;
  height: 100% !important;
  min-height: 1px;
  padding-right: 20px;
  /* opacity: 0.4; */
  transition: opacity 200ms linear;
}

.slick-slide > div {
  height: 100% !important;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-slide > div:focus {
  outline: none;
}

.slick-slide:hover {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
  content: "" !important;
}

.slick-current > div > .Scan__ScanMe {
  opacity: 1;
  transform: rotateY(0deg);
  transition: all 400ms linear 300ms;
}

.slick-current > div > .Scan__ScanMe:focus {
  outline: none;
}

.slick-next {
  right: 0px !important;
  top: 50% !important;
  transform: translate(25%, -50%) scale(0.6) !important;
  transition: all 250ms ease-in-out;
  height: auto !important;
  overflow-x: hidden;
  width: auto !important;
}

.slick-next:hover {
  opacity: 1 !important;
  transform: translateY(-50%) scale(1) !important;
  transition: all 250ms ease-in-out;
}

.slick-prev {
  left: -38px !important;
  top: 50% !important;
  transform: rotate(180deg) translate(25%, 50%) scale(0.6) !important;
  z-index: 30;
  transition: all 250ms ease-in-out;
  height: auto !important;
  width: auto !important;
}

.slick-prev:hover {
  opacity: 1 !important;
  transform: rotate(180deg) translateY(50%) scale(1) !important;
  transition: all 250ms ease-in-out;
}

.slick-arrow {
  opacity: 0.2;
}

.slick-dots > li > button {
  color: #000 !important;
}

.slick-dots > li > button:before {
  content: " " !important;
}

.Scanner__draw-square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Scanner__camera-flip {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Scanner__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Scan__scanner > .Scanner canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 283px;
  height: 186px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.Scan__scanner > .Scanner video {
  width: 283px;
  height: 186px;
  border-radius: 4px !important;
}

.Scan__scanner > .Scanner {
  bottom: 30px;
  right: 30px;
  width: 283px;
  height: 186px;
  border-radius: 4px;
}

.Scan__bottom-dots {
  background-color: rgba(0, 0, 0, 0.7);
  width: 22px;
  height: 8px;
}

.newScannedItem {
  height: 100%;
  transform: translateX(0px);
}

.newScannedItem__slide {
  transform: translateX(433px);
  opacity: 1;
  transition: 400ms transform linear;
}

.slick-current > div > div {
  opacity: 1 !important;
}

.Scan__isLoading {
  padding: 30px;
  height: 100%;
  width: 100%;
}
