.OrdersToday {
  display: flex;
  align-items: center;
  padding-right: 15px;
  border-right: 1px solid #a1b4c4;
  margin-right: 15px;
  height: 30px;
}

.OrdersToday__icon {
  margin-right: 6px;
}

.OrdersToday__text {
  font-size: 13px;
  color: #0c2c52;
}
