.OrderDetails {
  display: block;
  border: 1px solid #a1b4c4;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
}

.OrderDetails__addToInventory {
  height: 45px;
  width: 100%;
  background-color: #f1f4f6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #a1b4c4;
}

.OrderDetails__addToInventory-text {
  padding-right: 20px;
  padding-left: 5px;
  color: #4092d7;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.OrderDetails__addToInventory-checkbox {
  padding-right: 18px;
}

.OrderDetails__scanIn {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.OrderDetails__addToInventory-button {
  cursor: pointer;
  display: flex;
}

.OrderDetails__scan-text {
  color: #4092d7;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  margin-left: 6px;
}

.OrderDetails__items {
  background-color: #a1b4c4;
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 0 0 10px 10px;
}
