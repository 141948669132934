.HistoryEntry {
  min-height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a1b4c4;
  background-color: #fff;
  padding: 0 27px 0 27px;
  overflow: hidden;
}

.HistoryEntry:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}

.HistoryEntry__time {
  width: 135px;
  min-width: 135px;
}

.HistoryEntry__time-text {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
}

.HistoryEntry__operation-group {
  margin-left: 15px;
}

.HistoryEntry__operation-group-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.HistoryEntry__action {
  margin-left: 22px;
}

.HistoryEntry__action-text {
  color: #0c2c52;
  font-size: 15px;
  line-height: 17px;
}

.HistoryEntry__details {
  justify-self: flex-end;
  margin-left: auto;
}

.HistoryEntry__details-clicky-boi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 161px;
  border-left: 1px solid #a1b4c4;
  min-height: 55px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  margin-right: -27px;
}

.HistoryEntry__details-clicky-boi:hover {
  background-color: rgba(161, 180, 196, 0.1);
}

.HistoryEntry__details-clicky-boi > div > svg > path {
  fill: #4092d7;
}

.HistoryEntry__details-clicky-boi--text {
  margin-left: 5px;
  color: #4092d7;
  font-size: 15px;
  line-height: 17px;
}

/* */

.SettingsItemEdited {
  display: flex;
  color: #0c2c52;
  font-size: 15px;
  line-height: 17px;
}

.SettingsItemEdited__message {
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SettingsItemEdited__minimum {
  display: flex;
  align-items: center;
  margin-left: 4px;
  white-space: nowrap;
}

.SettingsItemEdited__minimum--changedto {
  font-weight: 700;
  margin-left: 3px;
}

.SettingsItemEdited__minimum--changedamount {
  border-radius: 4px;
  line-height: 13px;
  /* width: 19px; */
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  padding: 0 3px;
}

.SettingsItemEdited__minimum--changedamount--text {
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  /* transform: translateY(-1px); */
}

.SettingsItemEdited__maximum {
  display: flex;
  align-items: center;
  margin-left: 4px;
  white-space: nowrap;
}

.SettingsItemEdited__maximum--changedto {
  font-weight: 700;
  margin-left: 3px;
}

.SettingsItemEdited__maximum--changedamount {
  border-radius: 4px;
  line-height: 13px;
  min-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  padding: 0 3px;
}

.SettingsItemEdited__maximum--changedamount--text {
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
}
