.DashboardNotEnoughDataToCalculate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 69%;
}

.DashboardNotEnoughDataToCalculate__title {
  margin-bottom: 10px;
}

.DashboardNotEnoughDataToCalculate__title-text {
  font-size: 15px;
  color: #a1b4c4;
  font-weight: 700;
}

.DashboardNotEnoughDataToCalculate__sub-text {
  font-size: 13px;
  color: #a1b4c4;
}
