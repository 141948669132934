.Orders {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Orders__header {
  /* border-bottom: 1px solid #A1B4C4; */
  position: absolute;
  top: -57px;
  left: 38px;
  display: flex;
  vertical-align: top;
  width: 100%;
}

.Orders__dividerbar {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #a1b4c4;
  background-color: #a1b4c4;
  margin-left: 38px;
}

.Orders__actions {
  vertical-align: middle;
  position: absolute;
  right: 0px;
  height: 50px;
  width: calc(100% - 10px);
  background-color: #fff;
  z-index: 50;
  margin-right: 10px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.Orders__actions-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(161, 180, 196, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Orders__next-order-date {
  display: inline-block;
  color: #0c2c52;
  font-size: 13px;
  padding-right: 14px;
}

.Order__add-items {
  display: inline-block;
  padding-left: 17px;
  margin-right: 28px;
  border-left: 1px solid #a1b4c4;
}

.Orders__order-date {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  display: inline-block;
}

.Order__order-date-next {
  font-size: 20px;
  line-height: 30px;
  color: rgba(109, 122, 133, 0.8);
  display: inline-block;
}

.Orders__title {
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  padding-right: 254px;
  padding-bottom: 24px;
}

.Orders__content-wrapper {
  padding: 60px 28px 30px 38px;
  overflow-y: auto;
  height: 100%;
}

.Orders__content-wrapper::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.Orders__content-wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.Orders__content {
  border-radius: 10px;
  border: 1px solid #a1b4c4;
  margin-top: 9px;
}

.Orders__contact {
  border-bottom: 1px solid #a1b4c4;
  height: 89px;
}

.Orders__order-list {
  border-radius: 0 10px 10px 0;
}

.Orders__order-list-empty {
  text-align: center;
  width: 100%;
  color: #0c2c52;
  height: 55px;
  background-color: #fff;
  line-height: 55px;
  border-radius: 0 0 10px 10px;
}
