.SettingsScanner__Title {
  position: absolute;
  top: -57px;
}

.SettingsScanner__Title-Text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.SettingsScanner__Content {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #a1b4c4;
}

.SettingsScanner__Downloads {
  margin-top: 2rem;
}

.SettingsScanner__Downloadlinks {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  gap: 1px;
}

.SettingsScanner__Elements {
  background-color: #a1b4c4;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr);
  gap: 1px;
  padding-bottom: 1px;
}

.SettingsScanner__Elements:last-child {
  padding-bottom: 0px;
}

.SettingsScanner__Element {
  padding: 19px 27px;
  background-color: #fff;
}

.SettingsScanner__Element__Icon {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-block: 70px;
}

.SettingsScanner__Element > p {
  line-height: 18px;
  font-size: 15px;
}

.SettingsScanner__Element > label {
  color: #6d7a85;
  font-size: 11px;
  line-height: 13px;
}

.SettingsScanner__Download {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline-color: transparent;
  border: none;
  background-color: #fff;
  margin: auto;
}

.SettingsScanner__Download > * {
  cursor: pointer;
  pointer-events: none;
}

.SettingsScanner__Download:hover {
  text-decoration: underline;
}
