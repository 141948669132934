.NewItem {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.NewItem__exit {
  position: absolute;
  right: 39px;
  top: 39px;
}

.NewItem__content {
  padding: 0 93px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
}

.NewItem__addManual {
  margin-top: 10px;
  height: 16px;
  display: flex;
}

.NewItem__manual-text {
  padding-left: 5px;
  color: #4092d7;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  height: 16px;
}

.NewItem__manual-text:hover {
  text-decoration: underline;
}

.NewItem__footer {
  align-self: flex-end;
  margin-top: auto;
  height: 139px;
  width: 100%;
  background-color: rgba(161, 180, 196, 0.2);
  display: flex;
  padding-left: 95px;
  padding-right: 93px;
  padding-top: 17px;
  border-top: 1px solid #d8d8d8;
}

.NewItem__footer-delete {
  display: flex;
  align-self: flex-start;
}

.NewItem__footer-delete-circle {
  width: 25px;
  height: 25px;
  background-color: #aa2525;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.NewItem__footer-text {
  padding-left: 7px;
  line-height: 25px;
  opacity: 0.7;
  color: #6d7a85;
  font-size: 12px;
  font-weight: bold;
}

.NewItem__footer-changesSaved {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  width: 200px;
  justify-content: flex-end;
}

.NewItem__addButtonContent {
  display: flex;
  align-items: center;
}

.NewItem__creating-button-spin {
  animation: spin 800ms infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
