.NotificationBar {
  width: 288px;
  height: 50px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* z-index: 9999999; */
  border-radius: 4px;
}

.NotificationBar__success {
  background-color: #0C2C52;
}

.NotificationBar__error {
  background-color: #AA2525;
}

.NotificationBar__text {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding-left: 20px;
  line-height: 46px;
}

.NotificationBar__icon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}