/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~lightbox-react/style.css";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Neue Helvetica W01", Helvetica, Arial, sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  outline: none;
}

input,
textarea {
  font-family: "Neue Helvetica W01", Helvetica, Arial, sans-serif;
}

/* @media print {
  @page {
    margin: 0;
    size: landscape;
  }
  body {
    margin: 3px;
  }
} */

/* Grid overlay, to check that all is good */
/* :root {
  --offset: 2rem;
  --max_width: 72rem;
  --columns: 6;
  --gutter: 1rem;
  --color: hsla(204, 80%, 72%, 0.25);
}

:root {
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(
    to right,
    var(--color),
    var(--color) var(--column-width),
    transparent var(--column-width),
    transparent var(--repeating-width)
  );
}

html {
  position: relative;
}

html::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - (2 * var(--offset)));
  max-width: var(--max_width);
  min-height: 100vh;
  content: "";
  background-image: var(--background-columns);
  background-size: var(--background-width) 100%;
  z-index: 1000;
  pointer-events: none;
} */
