.NumberInput {
  display: flex;
  position: relative;
  max-width: 174px;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  justify-self: flex-end;
  margin-left: auto;
  max-height: 41px;
}

.NumberInput__has-error {
  border: 1px solid #e24f4e;
}
.NumberInput__has-error .NumberInput__field {
  background-color: rgb(246, 234, 234);
}

.NumberInput__button {
  width: 37px;
  height: 39px;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  border-right: 1px solid #a1b4c4;
}

.NumberInput__button:hover {
  background-color: rgba(64, 146, 215, 0.1);
  transition: all 150ms linear;
}

.NumberInput__addOne {
  border-radius: 4px 0 0 4px;
}

.NumberInput__field {
  padding: 0;
  width: 58px;
  height: 39px;
  outline: none;
  border: none;
  background-color: rgba(161, 180, 196, 0.2);
  border-radius: 0 4px 4px 0;
  text-align: right;
  padding-right: 36px;
  font-size: 15px;
  max-height: 39px;
}

.NumberInput__field--nounits {
  padding-right: 15px;
}

.NumberInput__error-icon {
  position: absolute;
  right: -46px;
}

.NumberInput__error-icon--target {
  display: flex;
  align-items: center;
  width: 39px;
  height: 39px;
}

.NumberInput__error-icon > div > div > svg > g > circle {
  fill: #ab2625;
}

.NumberInput__unit {
  position: absolute;
  right: 6px;
  color: #0c2c52;
  opacity: 0.4;
  font-size: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.NumberInput__cannot-edit {
  pointer-events: none;
  width: 94px;
  border: none;
  
}

.NumberInput__cannot-edit > input {
  background-color: #fff;
}