.ExportComponentWithDates {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.ExportComponentWithDates__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.ExportComponentWithDates__icon:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #a1b4c4;
  transition: all 250ms linear;
}
