.ProfileDropdown {
  border: 1px solid #A1B4C4;
  border-radius: 5px;
  background-color: #FFFFFF;
  position: absolute;
  display: block;
  width: 215px;
  z-index: 999999;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.15);
}

.ProfileDropdown__backdrop {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.ProfileDropdown__help {
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #A1B4C4;
}

.ProfileDropdown__settings {
  border-bottom: 1px solid #A1B4C4;
}

.ProfileDropdown__switch-app {
  border-bottom: 1px solid #A1B4C4;
}

.ProfileDropdown__logout {
  border-radius: 0 0 5px 5px;
}



.ProfileDropdown__item {
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 47px;
  transition: all 100ms linear;
  cursor: pointer;
  vertical-align: middle;
}

.ProfileDropdown__item:hover {
  background-color: rgba(161, 180, 196, .1);
  transition: all 250ms linear; 
}

.ProfileDropdown__text {
  padding-left: 10px;
}