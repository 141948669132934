.Layout {
  height: 100%;
  width: 100%;
  flex: 1;
}

.Layout__sidenav {
  position: absolute;
}

.Layout__content-background {
  position: absolute;
  left: 90px;
  top: 0;
  bottom: 0;
  right: 0;
}

.Layout__toolbar {
  height: 111px;
  background-color: rgba(161, 180, 196, 0.4);
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
}

.Layout__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Layout__routes {
  background-color: rgba(161, 180, 196, 0.4);
  height: 100%;
}

.Layout__order-date-changed {
  z-index: 1;
}

.OrderServiceError {
  z-index: 551;
  margin-left: -100px;
  background-color: #d69d23;
  height: 50px;
  display: grid;
  grid-template-columns: minmax(271px, 300px) 1fr minmax(271px, 300px);
}

.OrderServiceError__title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
}

.OrderServiceError__action {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
  font-size: 11px;
  line-height: 13px;
}

.OrderServiceError__spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
