.InactiveOrders {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.InactiveOrder {
  width: 100%;
  display: flex;
  height: 100%;
  height: 89px;
  background-color: rgba(161, 180, 196, 0.6);
  transition: all 200ms ease-in-out;
}

.InactiveOrder:hover {
  transition: all 200ms ease-in-out;
  background-color: rgba(161, 180, 196, 1);
}

.InactiveOrders__fullBorder {
  border-radius: 10px;
  border: 1px solid #a1b4c4;
}

.InactiveOrders__halfBorder {
  border-radius: 10px 10px 0 0;
  border: 1px solid #a1b4c4;
  border-bottom: none;
}

.InactiveOrders__icon {
  background-color: rgba(255, 255, 255, 0.2);
  width: 90px;
  height: 100%;
  display: flex;
  border-right: 1px solid #a1b4c4;
  position: relative;
  opacity: 0.5;
}

.InactiveOrders__iconHalf {
  border-radius: 10px 0 0 0;
}

.InactiveOrders__iconFull {
  border-radius: 10px 0 0 10px;
}

.InactiveOrders__icon > div > svg > path {
  fill: #0c2c52;
}

.InactiveOrders__order {
  display: flex;
  align-items: center;
  width: 100%;
}

.InactiveOrders__firstPart {
  flex: 1;
  max-width: 350px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.InactiveOrders__secondPart {
  flex: 1;
  margin-right: 40px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.InactiveOrders__order-number {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin-left: 24px;
  padding-bottom: 2px;
  white-space: nowrap;
}

.InactiveOrders__delivery-number {
  color: #6d7a85;
  font-size: 12.5px;  
  line-height: 18px;
  margin-left: 24px;
  padding-bottom: 2px;
  white-space: nowrap;
}

.InactiveOrders__order-date {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  padding-left: 24px;
}

.InactiveOrders__order-amount {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  padding-bottom: 2px;
}

.InactiveOrders__order-from {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
}

.IncomingOrders__order-status {
  flex: 1 1;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin-left: calc(-6rem + -10px);
}

.IncomingOrders__order-status-icon > div > svg > path {
  fill: #0c2c52;
}

.IncomingOrders__order-status-icon > div > svg {
  transform: translateY(-2px);
}

.IncomingOrders__order-status-text {
  padding-left: 5px;
  opacity: 0.8;
  color: #0c2c52;
  font-size: 12px;
  font-weight: bold;
}

.IncomingOrders__order-comments {

  height: 87px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin-left: calc(-6rem + -10px);
}

.InactiveOrders__see-order {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;
  justify-self: flex-end;
  margin-left: auto;
  height: 87px;
}

.InactiveOrders__see-order-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  position: relative;
}

.InactiveOrders__see-order-text::before {
  transition: 300ms;
  height: 1px;
  content: "";
  position: absolute;
  width: 0%;
  top: 21px;
}

.InactiveOrders__see-order-icon {
  transform: translate(11px, 1px) rotate(-90deg);
}

.InactiveOrders__completed-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.InactiveOrders__completed-icon > div {
  transform: translate(-50%, -45%) !important;
}

#ActiveOrder__commentclicker:hover 
.IncomingOrders__order-comments  {
  width: 83%;
  background-color: #0c2c52;
}
#ActiveOrder__commentclicker:hover
  ~ .InactiveOrders__see-order
  .InactiveOrders__see-order-text::before {
  width: 0% !important;
  background-color: #0c2c52;
}

.InactiveOrders__see-order:hover .InactiveOrders__see-order-text::before {
  width: 100%;
  background-color: #0c2c52;
}
.InactiveOrders__firstPart:hover
  ~ .InactiveOrders__see-order
  .InactiveOrders__see-order-text::before {
  width: 100%;
  background-color: #0c2c52;
}
.InactiveOrders__secondPart:hover
  ~ .InactiveOrders__see-order
  .InactiveOrders__see-order-text::before {
  width: 100%;
  background-color: #0c2c52;
}

.IncomingOrders__order-status:hover
  ~ .InactiveOrders__see-order
  .InactiveOrders__see-order-text::before {
  width: 100%;
  background-color: #0c2c52;
}
