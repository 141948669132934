.AutomaticAdd {
  position: relative;
  max-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AutomaticAdd__icon {
  margin: 0 4px;
}

.AutomaticAdd__text {
  color: #68CEA2;	
  font-size: 12px;	
  font-weight: bold;	
}