.DashboardMaterialBuys {
  background-color: #fff;
  height: 417px;
  position: relative;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
}

.DashboardMaterialBuys__title {
  display: flex;
  align-items: center;
  padding-left: 25px;
  height: 69px;
}

.DashboardMaterialBuys__title-text {
  font-size: 15px;
  color: #0c2c52;
  font-weight: 700;
}

.DashboardMaterialBuys__data {
  height: calc(100% - 69px);
  display: flex;
}

.DashboardMaterialBuys__ring-chart {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.DashboardMaterialBuys__list {
  height: 100%;
  display: inline-block;
  max-height: 290px;
  padding-left: 30px;
}

/* RingChartList */
.RingChartList {
  height: 100%;
}

.RingChartList__list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* RingChartListItem */
.RingChartListItem {
  display: flex;
}

.RingChartListItem__color-code {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  margin-right: 9px;
}

.RingChartListItem__information {
  display: flex;
  flex-direction: column;
}

.RingChartListItem__top {
  display: flex;
  color: #0c2c52;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

.RingChartListItem__index {
  margin-right: 6px;
}

.RingChartListItem__bottom {
  display: flex;
  margin-left: 18px;
}

.RingChartListItem__amount {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  margin-right: 6px;
}
