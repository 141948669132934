.ScanItemInModalAmountError {
  height: 100%;
}

.ScanItemInModalAmountError__header {
  height: 24%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
}

.ScanItemInModalAmountError__exit {
  position: absolute;
  right: 21px;
  top: 21px;
}

.ScanItemInModalAmountError__title {
  padding-bottom: 36px;
  align-self: flex-end;
  font-size: 25px;
  font-weight: bold;
}

.ScanItemInModalAmountError__title-text {
  text-align: center;
  margin-bottom: 8px;
}

.ScanItemInModalAmountError__subtitle {
  font-size: 15px;
  opacity: 0.6;
}

.ScanItemInModalAmountError__content {
  height: 301px;
  border-bottom: 1px solid #d8d8d8;
}

.ScanItemInModalAmountError__content > .Scanner video {
  height: 301px;
  width: 100%;
}

.ScanItemInModalAmountError__content > .Scanner {
  height: 301px !important;
  width: 100%;
}

.ScanItemInModalAmountError__content > .Scanner canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 301px;
}

.ScanItemInModalAmountError__static-area {
  position: fixed;
  width: 750px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 58px;
  margin: 0 45px;
  background-color: #fff;
}

.ScanItemInModalAmountError__static-text {
  opacity: 0.7;
  color: #6d7a85;
  font-size: 11px;
  padding-bottom: 13px;
}

.ScanItemInModalAmountError__amount-received {
  margin-left: 72px;
  margin-right: 42px;
}

.ScanItemInModalAmountError__items {
  height: 300px;
  padding: 0 45px;
  overflow-y: scroll;
  padding-top: 58px;
  width: 840px;
}

.ScanItemInModalAmountError__camera {
  display: flex;
  align-items: center;
}

.ScanItemInModalAmountError__camera-text {
  color: #4092d7;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.ScanItemInModalAmountError__information-area {
  margin: 0 87px 0 55px;
  display: flex;
  height: 40px;
  align-items: center;
}

.ScanItemInModalAmountError__scannedAmount {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.ScanItemInModalAmountError__scannedAmount-text {
  color: #6d7a85;
  opacity: 0.7;
  font-size: 12px;
  font-weight: bold;
}

.ScanItemInModalAmountError__scannedAmount-bar {
  height: 11px;
  width: 100px;
  margin-left: 9px;
}

.ScanItemInModalAmountError__actions {
  width: 100%;
  height: 85px;
  position: absolute;
  bottom: 0;
  display: flex;
  border-top: 1px solid #d8d8d8;
}

.ScanItemInModalAmountError__cancel-button {
  width: 50%;
  background-color: #f5f7f9;
  border-radius: 0 0 0 10px;
  color: #a1b4c4;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  transition: all 200ms ease-in-out;
}

.ScanItemInModalAmountError__cancel-button:hover {
  background-color: rgba(161, 180, 196, 0.2);
}

.ScanItemInModalAmountError__receive-now {
  width: 50%;
  border-radius: 0 0 10px 0;
  color: #ffffff;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  transition: all 200ms ease-in-out;
}

.ScanItemInModalAmountError__receive-now-errors {
  background-color: #aa2525;
}

.ScanItemInModalAmountError__receive-now-noerrors {
  background-color: #68cea2;
}

.ScanItemInModalAmountError__receive-now-errors:hover {
  background-color: #912121;
}
.ScanItemInModalAmountError__receive-now-noerrors:hover {
  background-color: rgb(89, 192, 147);
}

.ScanItemInModalAmountError__error-button {
  width: 50%;
  border-radius: 0 0 10px 0;
  background-color: #aa2525;
  color: #ffffff;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  transition: all 200ms ease-in-out;
}

.ScanItemInModalAmountError__error-button:hover {
  background-color: #912121;
}
