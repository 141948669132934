.ExportComponent {
  display: flex;
  height: 30px;
}

.ExportComponent__export {
  display: flex;
  height: 100%;
  padding: 0 10px;
  border: 1px solid transparent;
}

.ExportComponent__withBorders {
  border-left: 1px solid #a1b4c4;
  border-right: 1px solid #a1b4c4;
}

.ExportComponent__export-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border: 1px solid transparent;
}

.ExportComponent__export-icon:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  -webkit-transition: all 100ms linear;
  transition: all 250ms linear;
}

.ExportComponent__print-icon {
  display: inline-block;
  padding: 8px;
  border: 1px solid transparent;
}

.ExportComponent__print-icon:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  -webkit-transition: all 100ms linear;
  transition: all 250ms linear;
}

.ExportComponent__print-icon > div {
  transform: translateY(-4px);
}

/* PRINTABLE INVENTORY */
@media print {
  @page {
    margin: 0;
  }

  .PrintableInventory__table {
    position: relative;
    display: block;
    width: 100%;
    overflow: auto !important;
  }

  .PrintableInventory__row {
    position: relative;
    display: grid;
    grid-template-columns: 88px 2fr 1fr 1fr 1fr 10% 10%;
    padding: 10px;
    border: 1px solid #000;
    break-after: always;
  }

  .PrintableInventory__column {
    display: grid;
    grid-template-columns: 88px 2fr 1fr 1fr 1fr 10% 10%;
    overflow: initial !important;
  }

  .PrintableInventory__column > div {
    display: inline-block;
    overflow: initial !important;
  }

  .PrintableInventory__row > div {
    display: inline-block;
    overflow: initial !important;
  }

  .PrintableInventory__header {
    display: inline-block;
  }

  .PrintableInventory__column-name {
    font-weight: 700;
  }
}

.PrintableInventory__hidden {
  display: none;
  opacity: 0;
}
