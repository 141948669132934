.DashboardOrderCalendar {
  position: relative;
  height: 417px;
  background-color: #fff;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  margin-bottom: 200px;
}

.DashboardOrderCalendar__title {
  height: 69px;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.DashboardOrderCalendar__title-text {
  color: #0c2c52;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

.DashboardOrderCalendar__calendar {
  position: relative;
  margin: 0 auto;
  max-width: 248px;
  margin-top: 20px;
}

.DashboardOrderCalendar__calendar-static {
  display: flex;
}

.DashboardOrderCalendar__day {
  justify-content: center;
  color: #6d7a85;
  line-height: 17px;
  font-weight: 700;
  flex: 0 14.28571%;
  text-align: center;
  padding: 0.4rem;
}

/* Calendar styles */
.Calendar-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.Calendar-header {
  /* height: 50px;
  background: #333;
  color: #fff;
  text-align: center;
  line-height: 50px;
  display: flex;
  font-size: 20px;
  justify-content: space-between; */
  display: none;
}

.Calendar-header button {
  /* width: 50px;
  font-size: 20px;
  border: 0;
  background: transparent;
  color: #ddd;
  cursor: pointer; */
  display: none;
}

.DayCell {
  flex: 0 14.28571%;
  display: flex;
  justify-content: center;
}

.DayCell--current {
  font-weight: 700;
  background-color: #bada55;
}

.DayCell.nextMonth,
.DayCell.prevMonth {
  opacity: 0.3;
}

.Daycell__content {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.Daycell__day {
  text-align: center;
  font-size: 14px;
  color: #0c2c52;
}

.CalendarInfo {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}

.CalendarInfo__info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CalendarInfo__info-text {
  color: #6d7a85;
  font-size: 11px;
  line-height: 12px;
}

.FetchingOrders {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.CalendarError {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 40;
}

.CalendarError__title {
  font-size: 1.1rem;
  font-weight: 700;
  color: #0c2c52;
  margin-bottom: 8px;
}

.CalendarError__text {
  color: #6d7a85;
}
