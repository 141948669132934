.DashboardInventoryValueNumbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.DashboardInventoryValueNumbers__top {
  height: 50%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.DashboardInventoryValueNumbers__value {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-right: 20px;
}

.DashboardInventoryValueNumbers__value-container {
  display: flex;
  flex-direction: column;
}

.DashboardInventoryValueNumbers__values {
  display: flex;
}

.DashboardInventoryValueNumbers__value-number {
  /* font-size: 28px; */
  font-size: 24px;
  color: #8dbfe9;
  font-weight: 700;
  text-align: right;
  line-height: 34px;
}

.DashboardInventoryValueNumbers__value-number--small {
  font-size: 17px;
  font-weight: 700;
  text-align: right;
  line-height: 26px;
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}

.DashboardInventoryValueNumbers__value-label {
  align-self: flex-end;
  margin-left: auto;
  color: #a1b4c4;
  font-size: 11px;
  text-align: right;
  margin-right: 26px;
  transform: translateY(-4px);
}

.DashboardInventoryValueNumbers__value-block {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  padding-left: 10px;
}

.DashboardInventoryValueNumbers__change {
  display: flex;
  align-items: center;
  transform: translateY(-4px);
  justify-content: flex-end;
  margin-right: 26px;
  margin-left: auto;
  position: relative;
}

.DashboardInventoryValueNumbers__change-icon {
  position: absolute;
  left: 0;
}

.DashboardInventoryValueNumbers__change-icon > div {
  margin-right: 8px;
  transform: translateY(-2px);
}

.DashboardInventoryValueNumbers__bottom {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.DashboardInventoryValueNumbers__change-value {
  font-size: 11px;
  font-weight: 700;
  margin-left: 14px;
  min-width: 40px;
}

.DashboardInventoryValueNumbers__change-value--negative {
  color: #aa2525;
}
.DashboardInventoryValueNumbers__change-value--positive {
  color: #53a682;
}

.DashboardInventoryIntakeNumbers__change-value {
  color: #a1b4c4;
}

.DashboardInventoryIntakeNumbers__change-icon > div > svg > path {
  fill: #a1b4c4;
}

.DashboardInventoryOutgoingNumbers__change-icon > div {
  margin-right: 8px;
  transform: translateY(-2px);
}
.DashboardInventoryValueNumbers__change-icon--positive > div > svg > path {
  fill: #53a682;
}
.DashboardInventoryValueNumbers__change-icon--negative > div > svg > path {
  fill: #aa2525;
}

.DashboardInventoryOutgoingNumbers__change-value--negative {
  color: #aa2525;
}
.DashboardInventoryOutgoingNumbers__change-value--positive {
  color: #53a682;
}

.DashboardInventoryValueNumbers__usage {
  margin-top: 27px;
}

.DividerBar {
  border-bottom: 1px solid rgba(216, 216, 216, 0.2);
  width: 100%;
}

.IdentifierBox {
  width: 17px;
  height: 17px;
  border-radius: 2px;
}

@media (min-width: 1280px) {
  .DashboardInventoryValueNumbers__value-container {
    flex-direction: row;
  }

  .DashboardInventoryValueNumbers__change {
    transform: translateY(0);
  }
  .DashboardInventoryValueNumbers__value-label {
    margin-right: 117px;
  }

  .DashboardInventoryValueNumbers__value-label--small {
    margin-right: 114px;
  }
  .DashboardInventoryValueNumbers__value-number {
    font-size: 28px;
  }

  .DashboardInventoryValueNumbers__values {
    margin-right: 8px;
  }
}
