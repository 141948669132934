.SuperAdmin__accountslist {
  padding: 60px 70px;
}

.SuperAdmin__accountslist__title {
  position: absolute;
  top: -57px;
}

.SuperAdmin__accountslist__title-text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.SuperAdmin__newaccount {
  position: absolute;
  right: 70px;
  top: 22px;
}
