.ModalButton {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  height: 37px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 200px;
}

.ModalButton__icon-container {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 2px;
  height: 31px;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-in-out;
}

.ModalButton__text-container {
  position: absolute;
  right: 0;
}

.ModalButton__text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
}

/* Disabled button */

.disabledModalButton {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  height: 37px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: not-allowed;
  background-color: rgba(161, 180, 196, 0.5);
  gap: 4px;
}

.disabledModalButton__icon-container {
  justify-self: flex-start;
  background-color: #ffffff;
  border-radius: 2px;
  height: 31px;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease-in-out;
  margin-left: 3px;
}

.ModalButton__icon > div > svg > path {
  fill: #68cea2;
}

.disabledModalButton__text-container {
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 8px;
}

.disabledModalButton__text {
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
}

.ModalButton__icon > div > svg > g > circle {
  stroke: #aa2525;
}
.ModalButton__icon > div > svg > g > rect {
  fill: #aa2525;
}

.ModalButton__cannot-edit {
  pointer-events: none;
}

/* background-color: #AA2525; */
