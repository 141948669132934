.OrderComment {
  position: relative;
  display: flex;
  transform: translateY(2px);
}

.OrderComment:hover {
  cursor: pointer;
}

.OrderComment__static-text {
  margin-left: 8px;
  color: #4092d7;
  font-size: 15px;
  font-weight: 700;
}

.OrderComment__static-text::before {
  transition: 300ms;
  height: 1px;
  content: "";
  position: absolute;
  width: 0%;
  top: 18px;
}

.AmountDisplay {
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 2;
  background-color: #d69d23;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AmountDisplay__text {
  font-size: 12px;
  color: #fff;
  font-weight: 700;
}

.ViewOrderComments__backdrop {
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3100;
}

.ViewOrderComments__animator {
  position: fixed;
}

.ViewOrderComments {
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 100vw;
  width: 700px;
  background-color: #fff;
  z-index: 3103;
}

.ViewOrderComments__comments {
  height: 100%;
  padding: 0 95px;
}

.ViewOrderComments__title {
  padding-top: 109px;
  padding-bottom: 45px;
}

.ViewOrderComments__title-text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
}

.ViewOrderComments__comment-boxes {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
}

.ViewOrderComments__exit {
  position: absolute;
  top: 39px;
  right: 39px;
  cursor: pointer;
}

.CommentTrack__comment {
  padding: 34px 20px 32px 20px;
  position: relative;
  background-color: rgba(161, 180, 196, 0.15);
  border-bottom: 1px solid #a1b4c4;
}

.CommentTrack__comment:nth-child(2n) {
  background-color: rgba(72, 75, 77, 0.15);
}

.CommentTrack__comment:last-child {
  border-bottom: none;
}

.CommentTrack__title {
  position: absolute;
  top: 14px;
  left: 20px;
  font-size: 11px;
  color: #6d7a85;
  line-height: 12px;
  opacity: 0.7;
}

.CommentTrack__comment-box {
  color: #0c2c52;
  padding-top: 4px;
}

.CommentTrack__icon {
  position: absolute;
  top: 14px;
  right: 14px;
}

.CommentTrack__icon > div > svg > path {
  fill: rgba(161, 180, 196, 1);
}

.ViewOrderComments__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 700px;
  height: 140px;
}

.ViewOrderComments__content {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 95px;
  background-color: rgba(161, 180, 196, 0.2);
  border-top: 1px solid #a1b4c4;
}

.ViewOrderComments__icon {
  transform: translateY(-5px);
}

.ViewOrderComments__icon > div > svg > g > path:first-child {
  fill: #0c2c52;
}

.ViewOrderComments__text-block {
  margin-left: 14px;
  transform: translateY(-5px);
}

.ViewOrderComments__text-title {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

.ViewOrderComments__text-subtitle {
  font-size: 15px;
  line-height: 17px;
}

.OrderComment__static-text-dark {
  color: #0c2c52;
}

.OrderComment--dark > div > svg > path {
  fill: #0c2c52;
}

.OrderComment__static-text-dark::before {
  transition: 300ms;
  height: 1px;
  content: "";
  position: absolute;
  width: 0%;
  top: 18px;
}
