.ActiveOrders {
  border: 1px solid #a1b4c4;
  background-color: #ffffff;
  height: 89px;
  width: 100%;
  display: flex;
  transition: all 2 00ms ease-in-out;
  cursor: pointer;
}

.ActiveOrders:hover {
  background-color: #f7f7f7;
  transition: all 200ms ease-in-out;
}

.ActiveOrders__see-order:hover .ActiveOrders__see-order-text::before {
  width: 100%;
  background-color: #4092d7;
}
.ActiveOrders__firstPart:hover
  ~ .ActiveOrders__see-order
  .ActiveOrders__see-order-text::before {
  width: 100%;
  background-color: #4092d7;
}
.ActiveOrders__secondPart:hover
  ~ .ActiveOrders__see-order
  .ActiveOrders__see-order-text::before {
  width: 100%;
  background-color: #4092d7;
}

.ActiveOrders__not-expanded {
  border-radius: 10px;
}

.ActiveOrders__expanded {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.ActiveOrders__icon {
  border-radius: 10px 0 0 10px;
  background-color: #f1f4f6;
  width: 90px;
  height: 100%;
  display: flex;
  border-right: 1px solid #a1b4c4;
  position: relative;
}

.ActiveOrders__icon > div > svg > path {
  fill: #68cea2;
}

.ActiveOrders__order {
  display: flex;
  align-items: center;
  width: 100%;
}

.ActiveOrders__order-number {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  padding-left: 24px;
  padding-bottom: 2px;
}

.ActiveOrders__delivery-number {
  color: #6d7a85;
  font-size: 12.5px;  
  line-height: 18px;
  margin-left: 24px;
  padding-bottom: 2px;
  white-space: nowrap;
}

.ActiveOrders__order-date {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  padding-left: 24px;
}

.ActiveOrders__firstPart {
  flex: 1;
  max-width: 350px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.ActiveOrders__secondPart {
  margin-right: 40px;
  flex: 1;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.ActiveOrders__thirdPart {
  display: flex;
  margin-left: -6rem;
}

.ActiveOrders__order-amount {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  padding-bottom: 2px;
}

.ActiveOrders__order-from {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
}

.ActiveOrders__see-order {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 32px;
  justify-self: flex-end;
  margin-left: auto;
  height: 100%;
}

.ActiveOrders__see-order-text {
  color: #4092d7;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  position: relative;
  transform: translateY(-1px);
}

.ActiveOrders__see-order-text::before {
  transition: 300ms;
  height: 1px;
  content: "";
  position: absolute;
  width: 0%;
  top: 21px;
}

.ActiveOrder__order {
  margin-bottom: 14px;
  display: grid;
  grid-template-rows: 89px 0fr;
  overflow: hidden;
  transition: grid-template-rows 200ms ease-in-out;
}

.ActiveOrder__order--expanded {
  grid-template-rows: 89px 1fr;
  overflow: visible;
}
.ActiveOrder__order--expanded > .OrderDetails {
  visibility: visible;
}

.ActiveOrder__comment-clicker {
  height: 87px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: -10px;
}

.ActiveOrder__comment-clicker:hover .OrderComment__static-text::before {
  width: 83%;
  background-color: #4092d7;
}
