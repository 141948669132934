.Inventory {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Inventory__header {
  position: absolute;
  top: -57px;
  left: 38px;
  vertical-align: top;
  z-index: 16;
  display: flex;
  width: 100%;
}

.Inventory__dividerbar {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #a1b4c4;
  background-color: #a1b4c4;
  margin-left: 38px;
}

.Inventory__title {
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 24px;
  flex: 1;
}

.Inventory__search {
  height: 42px;
  width: 276px;
  transform: translate(-50%, -20%);
  position: absolute;
  top: 0;
  left: 138px;
  flex: 3;
}

.Inventory__actions {
  vertical-align: middle;
  position: absolute;
  right: 0px;
  height: 50px;
  width: calc(100% - 10px);
  background-color: #fff;
  z-index: 50;
  margin-right: 10px;
  transition: all 350ms linear;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  height: 66px;
}

.Inventory__actions-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(161, 180, 196, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Inventory__sorting {
  align-items: center;
  display: flex;
  margin-right: 30px;
  font-size: 13px;
}

.Inventory__newItem {
  padding-left: 17px;
  display: inline-block;
  margin-right: 28px;
}

.Inventory__items-wrapper {
  overflow-y: scroll;
  padding: 0 28px 0 38px;
}

.Inventory__items-wrapper::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.Inventory__items-wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.Inventory__items {
  padding-bottom: 30px;
}

.Inventory__items--amount {
  /* padding-top: 10px; */
}

.Inventory__category-title {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  padding-bottom: 9px;
}

.InventoryList__category-items {
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  background-color: #fff;
}

.InventoryList__category-items a:last-child {
  border-bottom: none;
}

.InventoryList__amount-items {
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 30px;
}

.InventoryList__amount-items:last-child {
  border-bottom: none;
}

@media (min-width: 1023px) {
  .Inventory__search {
    width: 430px;
    left: calc(50% - 38px);
  }
}

.Inventory__actions--shadowed {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.25);
  height: 49px;
}

.Inventory__error {
  padding: 38px;
  color: #0c2c52;
  opacity: 0.6;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
