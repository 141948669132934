.AppUpdatedIndicator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.AppUpdatedIndicator__box {
  height: 45%;
  background-color: #3db882;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 90px;
  padding-right: 169px;
}

.AppUpdatedIndicator__content {
  display: flex;
  max-width: 60%;
}

.AppUpdatedIndicator__content__icon {
  margin-right: 20px;
  transform: translateY(4px);
}

.AppUpdatedIndicator__content__text {
  color: #fff;
}

.AppUpdatedIndicator__content__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.AppUpdatedIndicator__content__subtitle {
  line-height: 17px;
  opacity: 0.7;
  font-size: 15px;
  margin-top: 5px;
}

.AppUpdatedIndicator__actions {
  justify-self: flex-end;
  margin-left: auto;
  min-width: 175px;
}

.AppUpdatedIndicator__actions__timer {
  height: 23px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 1440px) {
  .AppUpdatedIndicator__box {
    padding-left: 150px;
    padding-right: 200px;
  }
}

@media (min-width: 1920px) {
  .AppUpdatedIndicator__box {
    padding-left: 210px;
    padding-right: 270px;
  }
}
