/* Button Styles */

.Button {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.Button__appUpdate {
  background-color: #fff;
  height: 41px;
  width: 175px;
  border-radius: 4px;
}

.Button__newItem {
  height: 30px;
  background-color: #4092d7;
  border-radius: 4px;
  position: relative;
}

.Button__newItem:hover {
  background-color: rgb(52, 127, 189);
  transition: all 250ms ease-in-out;
}

.Button__regret {
  /* height: 30px; */
  height: 100%;
  border: 1px solid #a1b4c4;
  /* background-color: #4092D7; */
  border-radius: 4px;
  position: relative;
  transition: all 250ms ease-in-out;
}

.Button__regret:hover {
  background-color: rgb(189, 52, 52);
  transition: all 250ms ease-in-out;
}

.Button__trigger {
  height: 100%;
  background-color: rgba(161, 180, 196, 0.2);
  border: 1px solid #a1b4c4;
  border-radius: 4px;
}

.Button__createButton {
  background-color: #68cea2;
  border-radius: 4px;
  border: 1px solid #53a682;
  height: 100%;
}

.Button__createButton > div > div > svg {
  transform: translateY(1px);
}

.Button__createButton:hover {
  background-color: rgb(89, 192, 147);
  transition: all 150ms linear;
}

.Button__showMore {
  border: 1px solid #6d7a85;
  border-radius: 4px;
}

.Button__toggleButton {
  width: 100%;
  height: 100%;
}

.Button__confirm {
  width: 100%;
  height: 100%;
  background-color: #68cea2;
  border-radius: 0 0 10px 0;
}

.Button__cancel {
  width: 100%;
  height: 100%;
  background-color: #f5f7f9;
  border-radius: 0 0 0 10px;
}

/* Label Styles */

.Button__text {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  display: flex;
}

.Button__text > .Icon {
  margin-right: 3px;
}

.Button__trigger-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 115%);
  opacity: 0.7;
  color: #6d7a85;
  font-size: 11px;
  text-align: center;
  font-weight: normal;
}

.createText {
  height: 100%;
  line-height: 35px;
  font-size: 15px;
  font-weight: normal;
}

.Button__showMoreOrders {
  transform: translateX(0);
  color: #0c2c52;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  opacity: 0.7;
  padding: 0 5px;
}

.Button__confirm-text {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 85px;
  transform: translateX(0px);
}

.Button__cancel-text {
  color: #a1b4c4;
  font-size: 18px;
  font-weight: bold;
  line-height: 85px;
  transform: translateX(0px);
}

.Button__inactive-hover {
  background-color: #a1b4c3;
  transition: all 150ms linear;
  border: 1px solid rgba(161, 180, 196, 0.4);
  cursor: default;
}

.Button__inactive-hover:hover {
  background-color: #a1b4c3;
}

.appUpdate {
  color: #3db881;
}
