.AddItemDetails {
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.AddItemDetails__edit {
  position: absolute;
  top: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AddItemDetails__edit-text {
  color: #4092d7;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  padding-left: 7px;
}

.AddItemDetails__atrribute {
  height: 53px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
}

.AddItemDetails__name {
  border-top: 1px solid #d8d8d8;
}

.AddItemDetails__currentStock {
  justify-self: flex-end;
  width: 306px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.AddItemDetails__stock-bar {
  width: 40%;
  justify-self: flex-start;
  height: 11px;
}

.AddItemDetails__numberInput {
  width: 55%;
  justify-self: flex-end;
  margin-left: auto;
}

.AddItemDetails__auto-reorder {
  max-width: 306px;
  justify-self: flex-end;
  margin-left: auto;
}

.AddItemDetails__numberOnlyInput {
  width: 33%;
  justify-self: flex-end;
  margin-left: auto;
}

@media (min-height: 800px) {
  .AddItemDetails {
    padding-top: 45px;
  }
}
