.SettingsAccess__checkbox-wrapper {
  margin-right: 10px;
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
}

.SettingsAccess__checkbox > .Select {
  width: 170px;
}

.SettingsCompanyContent__indent {
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: rgb(154, 181, 198);
}
