.OrderContact {
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #f1f4f6;
  display: flex;
  align-items: center;
}

.OrderContact__image-container {
  height: 89px;
  width: 90px;
  border-radius: 10px 0 0 0;
  background-color: #0c2c52;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrderContact__text {
  margin-left: 1rem;
}

.OrderContact__static-text {
  color: #0c2c52;
  line-height: 18px;
  font-size: 15px;
  font-weight: bold;
}

.OrderContact__contact-details {
  color: #0c2c52;
  font-size: 15px;
  line-height: 17px;
}

.OrderContact__order-now {
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 26px;
  /* width: 218px; */
  height: 37px;
  display: flex;
  align-items: center;
}

.OrderContact__comment {
  margin-right: 16px;
  width: 160px;
  color: #4092d7;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  cursor: pointer;
}

.OrderContant__order-button {
  width: 118px;
}

.OrderContact__comment-icon {
  margin-right: 5px;
  transform: translateY(3px);
}

.OrderContact__comment-text {
  text-decoration: underline;
}
