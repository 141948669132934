.Icon__center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Icon__arrow-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}

.Icon__button-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-45%)
}

.Icon__inline {
  display: inline-block;
}

.Icon__rotate {
  transform: rotate(90deg);
}
