.Toolbar {
  height: 42px;
  width: 100%;
  position: relative;
  top: 34px;
}

.Toolbar__content {
  float: right;
  padding-right: 40px;
  vertical-align: middle;
  max-height: 42px;
  z-index: 5;
}

.Toolbar__functions {
  display: inline-block;
  position: relative;
  height: 100%;
  z-index: 5;
}

.Toolbar__notifications {
  display: inline-block;
  padding: 11px 20px 13px 16px;
  position: relative;
  transform: translateY(-25%);
  margin-right: 6px;
  border: 1px solid transparent;
  transition: all 100ms linear;
}

.Toolbar__notifications:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  transition: all 100ms linear;
}

.Toolbar__divider {
  height: 48px;
  width: 1px;
  background-color: #a1b4c4;
  content: "";
  display: inline-block;
}

.Toolbar__profile {
  position: relative;
  padding-left: 6px;
  z-index: 62;
}

.Toolbar__profile-icon {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
}

.Toolbar__profile-text {
  padding-left: 57px;
}

.Toolbar__profile-name {
  color: #0c2c52;
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 15px;
}

.Toolbar__profile-location {
  opacity: 0.5;
  color: #0c2c52;
  font-size: 11px;
  line-height: 12px;
}

.Toolbar__profile-arrow {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(15%);
}

/* CSS TRANSITIONS */
.slideOut-appear {
  height: 0px;
  opacity: 0.01;
  overflow: hidden;
}

.slideOut-appear.slideOut-appear-active {
  height: 400px;
  opacity: 1;
  transition: height 200ms ease-in-out, opacity 200ms ease-in-out;
}

.slideOut-leave {
  height: auto;
}

.slideOut-leave.slideOut-leave-active {
  height: 0px;
  opacity: 0.01;
  transition: height 200ms ease-in-out, opacity 200ms ease-in-out;
}
