.DashboardTotals {
  height: 417px;
  background-color: #fff;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.DashboardTotals__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.DashboardTotals__title {
  height: 69px;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.DashboardTotals__title-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
}

.DashboardTotals__content {
  display: flex;
  flex-direction: row;
}

.DashboardTotals__treemap {
  padding-right: 20px;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  height: 300px;
}

.DashboardTotals__list {
  padding-left: 20px;
  max-height: 260px;
}
