.AccountListItem {
  display: flex;
  flex-direction: row;
  height: 55px;
  align-items: center;
  border-bottom: 1px solid #a1b4c4;
  padding: 0 20px;
}

.AccountListItem:last-child {
  border-bottom: none;
}

.AccountListItem__name {
  flex: 1;
  font-weight: 700;
}

.AccountListItem__addUser {
  justify-self: flex-end;
  margin-left: auto;
  margin: 0 6px;
}

.AccountListItem__link {
  padding: 10px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: #5782b3;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.AccountListItem__link:hover {
  background-color: rgba(114, 166, 213, 0.25);
}
