.SettingsHistoryContent__list {
  border-radius: 10px;
  border: 1px solid #a1b4c4;
  position: relative;
}

.SettingsHistoryHeader {
  background-color: #0c2c52;
  height: 55px;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0 0;
}

.SettingsHistoryHeader__text {
  color: #a1b4c4;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.SettingsHistoryHeader__text-time {
  margin-left: 27px;
}

.SettingsHistoryHeader__text-action {
  margin-left: 148px;
}

.SettingsAccess__fetch-more {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 34px 0;
}

/**/

.SettingsHistorySearch {
  position: absolute;
  top: -63px;
  left: 55%;
  transform: translateX(-50%);
  z-index: 900;
  width: 420px;
}

.SettingsHistorySearch__input-field {
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: rgba(161, 180, 196, 0.5);
  width: 100%;
  color: #0c2c52;
  font-size: 17px;
  line-height: 20px;
  padding: 11px 20px;
  transition: all 300ms ease-in-out;
  box-sizing: border-box;
}

.SettingsHistorySearch__input-field:focus {
  background-color: #fff;
  color: #000;
  opacity: 1;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.SettingsHistorySearch__input-field:hover {
  background-color: #fff;
  color: #000;
  opacity: 1;
  /* box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15); */
}

.SettingsHistorySearch__input-field::placeholder {
  color: #0c2c52;
  font-size: 17px;
  line-height: 20px;
}

.SettingsHistorySearch_help {
  position: absolute;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.7;
  transition: opacity 200ms ease-in-out;
}

.SettingsHistorySearch_help:hover {
  opacity: 1;
}

.SettingsHistorySearch__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  right: 10px;
  cursor: pointer;
}

.HelpTooltipContent {
  padding: 25px;
}

.HelpTooltipContent__header {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 17px;
}

.HelpTooltipContent__list {
  list-style: inside;
}

.HelpTooltipContent__list-point {
  white-space: nowrap;
  margin-bottom: 6px;
  font-size: 15px;
}

.HelpTooltipContent__list-point:before {
  content: "";
  margin-left: -8px;
}

/* Help icon styling */
.SettingsHistorySearch_help-tooltip > .Icon > svg > g > circle {
  fill: #0c2c52;
}

.SettingsHistorySearch_help-tooltip > .Icon > svg > g > path {
  fill: #fff;
  /* fill: #dae1e8; */
}

.HelpTooltipContent__list-point > i {
  color: #7f8e9c;
}
