.OrderItemLoading {
  display: flex;
  height: 55px;
  border-top: 1px solid #a1b4c4;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #6d7a85;
}

.OrderItemLoading-id {
  color: #6d7a85;
  font-size: 15px;
  margin-left: 8px;
}

.OrderItemLoading__bar {
  height: 12px;
  margin-right: 10px;
  align-self: center;
  width: 90%;
}
