.DeleteItem {}

.DeleteItem__title {
  color: #0C2C52;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  padding: 0 30px;
  padding-top: 50%;
}

.DeleteItem__item {
  padding-top: 24px;
  color: #686868;
  font-size: 14px;
  text-align: center;
  line-height: 19px;
}

.DeleteItem__name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 30px;
}

.DeleteItem__description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 30px;
}

.DeleteItem__actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.DeleteItem__revert {
  height: 97px;
  background-color: #F5F7F9;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
}

.DeleteItem__revert-text {
  font-size: 18px;
  color: #A1B4C4;
  font-weight: bold;
  text-align: center;
}

.DeleteItem__delete-now {
  display: flex;
  width: 100%;
  height: 97px;
  align-items: center;
  justify-content: center;
  background-color: #AA2525;
  border-radius: 0 0 10px 10px;
}

.DeleteItem__delete-now-text {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-left: 10px;
}

.DeleteItem__delete-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.DeleteItem__delete-product {
  position: absolute;
  top: 26px;
  right: 28px;
  border: 1px solid #AA2525;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all .1s linear;
  background-color: #AA2525;
}

.DeleteItem__delete-product:hover {
  background-color: rgb(143, 31, 31);
  transition: all .1s linear;
}

.DeleteItem__delete-ring>div>svg>path {
  fill: #fff;
}