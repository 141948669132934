.WatchedProduct {
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #a1b4c4;
  display: flex;
  align-items: center;
  padding: 0 15px 0 24px;
  position: relative;
}

.WatchedProduct__ActionTarget {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.WatchedProduct_.WatchedProduct__ActionTarget:hover ~ .WatchedProduct {
  /* background-color: #e2e3e4; */
  background-color: #bada55;
}

.WatchedProduct__image {
  border: 1px solid #d8d8d8;
  height: 71px;
  width: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.WatchedProduct__texts {
  margin-left: 14px;
  height: 71px;
}

.WatchedProduct__title {
  max-width: 180px;
}

.WatchedProduct__title-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.WatchedProduct__text-content {
  color: #6d7a85;
  font-size: 13px;
  line-height: 19px;
  margin-top: 4px;
  max-width: 190px;
}

.WatchedProduct__buttons {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  z-index: 10;
}

/* LOADER CSS */
.WatchedProductLoader {
  background-color: rgba(161, 180, 196, 0.1);
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a1b4c4;
}

.WatchedProductLoader__image {
  width: 65px;
  height: 65px;
  margin-left: 27px;
}

.WatchedProductLoader__texts {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 17px;
}

.WatchedProductLoader__text {
  height: 18px;
  width: 190px;
}

.WatchedProductLoader__text-top {
  margin-bottom: 20px;
}

.WatchedProduct__button {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.WatchedProduct__buttons--ignore-button {
  border: 1px solid #a1b4c4;
  margin-right: 4px;
  transition: all 200ms ease-in-out;
}

.WatchedProduct__buttons--ignore-button > div {
  transform: translateY(1px);
}

.WatchedProduct__buttons--ignore-button > div > svg > path {
  fill: rgba(161, 180, 196, 0.5);
  transition: all 200ms ease-in-out;
}

.WatchedProduct__buttons--ignore-button:hover {
  background-color: #eac75a;
}

.WatchedProduct__buttons--ignore-button:hover > div > svg > path {
  fill: rgba(255, 255, 255, 1);
}

.WatchedProduct__buttons--action-button {
  border: 1px solid #4092d7;
  transform: rotate(-90deg);
  transition: all 200ms ease-in-out;
}

.WatchedProduct__buttons--action-button > div {
  transform: translateY(-1px);
}

.WatchedProduct__buttons--action-button > div > svg > path {
  fill: #4092d7;
  opacity: 1;
  transition: all 200ms ease-in-out;
}

.WatchedProduct__buttons--action-button:hover {
  background-color: #4092d7;
}

.WatchedProduct__buttons--action-button:hover > div > svg > path {
  fill: #fff;
}
