.DelayedOrders {
  margin-top: 24px;
}

.DelayedOrders__header {
  display: flex;
  align-items: center;
}

.DelayedOrders__title {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
}

.DelayedOrders__content {
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 9px;
}

.DelayedOrders__content > div {
  border-bottom: 1px solid #a1b4c4;
}

.DelayedOrders__content > div:last-child {
  border-bottom: none;
}

.DelayedOrderItem {
  display: grid;
  grid-template-columns: 49px 58px 2fr 2fr 10px 135px 70px 190px;
  height: 55px;
  align-items: center;
}

.DelayedOrderItem__productnumber {
  color: #6d7a85;
  font-size: 15px;
}

.DelayedOrderItem__name {
  color: #0c2c52;
  font-weight: 700;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.DelayedOrderItem__description {
  color: #6d7a85;
  font-size: 15px;
}

.DelayedORderItem__amount {
  color: #6d7a85;
  font-size: 15px;
  justify-self: center;
}

.DelayedOrderItem__readd {
  display: flex;
  transition: all 300ms ease-in-out;
  justify-self: flex-end;
  margin-right: 10px;
  align-items: center;
  border: 1px solid #4092d7;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.DelayedOrderItem__readd:hover > .DelayedOrderItem__readd-text {
  border-color: rgb(52, 127, 189);
}

.DelayedOrderItem__readd:hover {
  border-color: rgb(52, 127, 189);
}

.DelayedOrderItem__readd:hover > div > svg > path {
  fill: rgb(52, 127, 189);
}

.DelayedOrderItem__readd > div > svg > path {
  fill: #4092d7;
}

.DelayedOrderItem__readd-text {
  margin-left: 10px;
  color: #4092d7;
  font-size: 15px;
}

.DelayedORderItem__align-left {
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 4px;
}
