.AdminMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 170px;
  width: 268px;
  height: 100vh;
  border-right: 1px solid #a1b4c4;
  background-color: #fff;
  z-index: 560;
}

.AdminMenu__title {
  position: absolute;
  top: 57px;
  left: 0;
  margin-left: 20px;
  z-index: 20;
}

.AdminMenu__title-text {
  font-size: 22px;
  color: #0c2c52;
  font-weight: 700;
}

.AdminMenu__sub-title-text {
  color: #848c96;
  opacity: 0.7;
  font-size: 22px;
  font-weight: 400;
}
