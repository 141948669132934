.ScanNotInInventoryModal__backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScanNotInInventoryModal {
  width: 600px;
  height: 440px;
  z-index: 710;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScanNotInInventoryModal__close-button {
  position: absolute;
  top: 21px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.ScanNotInInventoryModal__close-text {
  opacity: 0.7;
  color: #6d7a85;
  font-weight: 700;
  font-size: 12px;
}

.ScanNotInInventoryModal__title {
  margin-top: 107px;
  max-width: 350px;
}

.ScanNotInInventoryModal__title-text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.ScanNotInInventoryModal__info {
  margin-top: 60px;
}

.ScanNotInInventoryModal__info-icon {
  display: flex;
  justify-content: center;
}

.ScanNotInInventoryModal__info-text {
  max-width: 360px;
  color: #4092d7;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 7px;
  line-height: 1.25em;
}

.ScanNotInInventoryModal__buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 85px;
  display: flex;
  border-top: 1px solid #d8d8d8;
  cursor: pointer;
}

.ScanNotInInventoryModal__confirm {
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: #4092d7;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
