.NoFoundItems {
  margin-top: 70px;
  width: 100%;
  height: 60px;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
}

.NoFoundItems__error-msg {
  color: #0C2C52;
  font-size: 20px;
  font-weight: bold;
}