.SuperAdmin {
  padding-left: calc(268px - 90px);
  overflow: auto;
  height: 100%;
  padding-bottom: 40px;
}

.SuperAdmin__accounts {
  background-color: #fff;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  margin-top: 7px;
}

.SuperAdmin__newaccount {
  display: flex;
  margin-left: auto;
  height: 37px;
  margin-bottom: 12px;
}

.SuperAdmin__newaccount-button {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
}

.SuperAdmin__newaccount-button > div {
  margin: 0 6px;
}
