.ProgressBar {
  width: 100%;
  height: 100%;
  background-color: rgba(161, 180, 196, 0.3);
  position: relative;
  border-radius: 7px;
}

.ProgressBar__bar {
  height: 100%;
  display: block;
  border-radius: 7px 0 0 7px;
  transition: width 300ms linear;
}

.ProgressBar__bar-text {
  position: absolute;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  width: 100%;
}
