.TextInput {
  height: 100%;
  justify-self: flex-end;
  display: flex;
  color: #0C2C52;
  margin-left: auto;
}

.TextInput-input {
  justify-self: flex-end;
  border: 1px solid #A1B4C4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  outline: none;
  min-width: 289px;
  margin-left: auto;
  font-size: 15px;
  padding: 9px 15px 11px 14px;
}

.TextInput__readOnly {
  justify-self: flex-end;
  text-align: right;
  font-size: 15px;
  line-height: 17px;
  margin-left: auto;
}