.Profile {
  width: 214px;
  height: 48px;
  position: relative;
  vertical-align: middle;
  border: 1px solid transparent;
  transition: all 100ms linear;
}

.Profile:hover {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  transition: all 100ms linear;
}

.Profile__icon {
  display: inline-block;
  padding-left: 22px;
  line-height: 46px;
}

.Profile__text {
  padding-left: 10px;
  display: inline-block;
  /* transform: translateY(2px); */
}

.Profile__username {
  color: #0c2c52;
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}

.Profile__location {
  opacity: 0.5;
  color: #0c2c52;
  font-size: 11px;
  line-height: 12px;
}

.Profile__arrowIcon {
  position: absolute;
  right: 10.73px;
  top: 50%;
  transform: translateY(-75%);
}
