.ItemSearchBar {
  position: relative;
  height: 42px;
  vertical-align: top;
  width: 100%;
  display: flex;
}

.ItemSearchBar__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ItemSearchBar__search {
  position: relative;
  width: calc(100% - 112px);
  transition: 200ms ease-in-out all;
}

.ItemSearchBar__input {
  z-index: 4;
  position: relative;
  outline: none;
  height: 42px;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  padding-left: 15px;
  font-size: 15px;
  vertical-align: top;
  transition: all 0.1s linear;
  box-sizing: inherit;
  width: 100%;
}

.ItemSearchBar__input:hover {
  outline: none;
  border: 1px solid rgb(118, 144, 165);
  background-color: rgba(161, 180, 196, 0.1);
}

.ItemSearchBar__input:focus-within {
  background-color: #fff;
  outline: none;
  transition: all 0.1s linear;
}

.ItemSearchBar__search--active {
  width: 100%;
}

.ItemSearchBar__search--active ~ .ItemSearchBar__actions {
  opacity: 0;
}

.ItemSearchBar__actions {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.ItemSearchBar__searchIcon {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-46%);
  z-index: 5;
}

.ItemSearchBar__button {
  position: relative;
  height: 42px;
}

.ItemSearchBar__button-active > .ItemSearchBar__icon > div > svg > path {
  fill: #fff;
}

.ItemSearchBar__button-active > .ItemSearchBar__icon > div > svg > g > path {
  fill: #fff;
}

.ItemSearchBar__button-active > .ItemSearchBar__icon > div > svg > g > rect {
  fill: #fff;
}

.ItemSearchBar__button-active:hover > .ItemSearchBar__icon > div > svg > path {
  fill: #fff !important;
}

.ItemSearchBar__button-active > .Button__trigger {
  background-color: #4092d7;
}

.ItemSearchBar__button-active:hover > .Button__trigger {
  background-color: #4092d7 !important;
}

.ItemSearchBar__button:hover > .Button {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  transition: all 0.1s ease-in-out;
}

.ItemSearchBar > div > .Scanner canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 283px;
  height: 186px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.ItemSearchBar > div > .Scanner video {
  width: 283px;
  height: 186px;
  border-radius: 4px !important;
}

.ItemSearchBar > div > .Scanner {
  bottom: 30px;
  right: 30px;
  width: 283px;
  height: 186px;
  border-radius: 4px;
}

.ItemSearchBar__isSearching {
  position: absolute;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  z-index: 40;
  overflow-y: auto;
  top: 40px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ItemSearchBar__isSearching-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 2px;
  line-height: 18px;
}
