.App {
  position: relative;
  width: 100%;
  height: 100vh;
}

.App__content {
  height: 100%;
  width: 100%;
  position: relative;
}

.mainRoute {
  position: absolute;
  height: 100%;
  width: 100%;
}

.routing__overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 600;
}

.u-no-pointer {
  pointer-events: none;
}

.routing__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 768px;
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  z-index: 603;
}

.react-tooltip-lite {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 5px;
}

.react-tooltip-lite-arrow {
  border-color: #fff;
  /* box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.15); */
}
