.EditItem {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.EditItem__close {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.EditItem__close:hover > .EditItem__closeborder {
  border: 1px solid rgba(109, 122, 133, 1);
  transition: all 100ms linear;
}

.EditItem__closeborder {
  border: 1px solid rgba(109, 122, 133, 0.3);
  transition: all 100ms linear;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditItem__content {
  padding: 0 95px;
  height: calc(100% - 139px);
  overflow-y: auto;
  padding-bottom: 20px;
  flex: 9;
}

.EditItem__content::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.EditItem__content::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.EditItem__edit {
  position: absolute;
  top: 51px;
  display: flex;
}

.EditItem__edit-text {
  color: #4092d7;
  font-size: 12px;
  font-weight: bold;
  padding-top: 6px;
  padding-left: 8px;
}

.EditItem__title {
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  padding-top: 109px;
  padding-bottom: 45px;
}

.EditItem__discontinued {
  transform: translateY(-36px);
  width: fit-content;
  display: flex;
  align-items: center;
}

.EditItem__discontinued__tag {
  background-color: #d69d23;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
}

.EditItem__discontinued__tag > .EditItem__discontinued__tag-text {
  padding: 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
}

.EditItem__discontinued--tooltip > .Icon > svg > g > circle {
  fill: #a1b4c4;
  /* fill: #d69d23; */
}

.EditItem__discontinued--tooltip > .Icon > svg > g > path {
  fill: #fff;
}

.EditItem__discontinued--tooltip {
  margin-left: 8px;
}

.EditItem__discontinued--tooltip-content {
  padding: 18px;
  font-size: 15px;
  color: #0c2c52;
}

.EditItem__option {
  border-top: 1px solid #d8d8d8;
  height: 53px;
  display: flex;
}

.EditItem__TextInputField {
  display: flex;
  align-items: center;
  width: 100%;
}

.EditItem__TextInputField-title {
  justify-self: flex-start;
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
}

.EditItem__TextInputField-input {
  justify-self: flex-end;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  outline: none;
  min-width: 289px;
  margin-left: auto;
  text-align: right;
  color: #0c2c52;
  font-size: 15px;
  padding: 9px 15px 11px 0;
}

.EditItem__static-text {
  justify-self: flex-end;
  color: #0c2c52;
  font-size: 15px;
  text-align: right;
  margin-left: auto;
}

.EditItem__SelectInputField {
  display: flex;
  align-items: center;
  width: 100%;
}

.EditItem__TextInputField-input:focus {
  background-color: #fff;
  transition: all 200ms linear;
}

.EditItem__select-title {
  justify-self: flex-start;
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
}

.EditItem__select {
  min-width: 306px;
  justify-self: flex-end;
  margin-left: auto;
  position: relative;
  z-index: 1;
}

.EditItem__currentStock {
  justify-self: flex-end;
  width: 306px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.EditItem__stock-bar {
  width: 40%;
  justify-self: flex-start;
  height: 11px;
}

.EditItem__numberInput {
  width: 55%;
  justify-self: flex-end;
  margin-left: auto;
}

.EditItem__auto-reorder {
  max-width: 306px;
  justify-self: flex-end;
  margin-left: auto;
}

.EditItem__numberOnlyInput {
  width: 33%;
  justify-self: flex-end;
  margin-left: auto;
}

.EditItem__maximum {
  border-bottom: 1px solid #d8d8d8;
}

.EditItem__barrier {
  background-color: #a1b4c4;
  opacity: 0.1;
  height: 12px;
  width: 100%;
}

.EditItem__manual-order {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  position: relative;
}

.EditItem__manual-order-input {
  border: 1px solid #a1b4c4;
  border-right: none;
  border-radius: 4px 0 0 4px;
  background-color: rgba(161, 180, 196, 0.2);
  width: 47px;
  height: 35px;
  padding: 0;
  padding-right: 37px;
  color: #0c2c52;
  font-size: 15px;
  text-align: right;
}

.EditItem__manual-order__units {
  position: absolute;
  top: 50%;
  right: 143px;
  transform: translateY(-50%);
  opacity: 0.4;
  color: #0c2c52;
  font-size: 15px;
}

.EditItem__footer {
  flex: 1;
  bottom: 0;
  background-color: rgba(161, 180, 196, 0.2);
  display: flex;
  padding-left: 95px;
  padding-right: 97px;
  padding-top: 17px;
  border-top: 1px solid #d8d8d8;
}

.EditItem__footer-delete {
  display: flex;
  align-self: flex-start;
}

.EditItem__footer-delete-circle {
  width: 25px;
  height: 25px;
  background-color: #aa2525;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.EditItem__footer-delete:hover .EditItem__footer-delete-circle {
  background-color: #991f1f;
}

.EditItem__footer-delete:hover .EditItem__footer-text {
  text-decoration: underline;
}

.EditItem__footer-text {
  padding-left: 7px;
  line-height: 25px;
  opacity: 0.7;
  color: #6d7a85;
  font-size: 12px;
  font-weight: bold;
}

.EditItem__footer-changesSaved {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  opacity: 1;
  transition: opacity 200ms linear;
}

.EditItem__footer-changesSaved-no {
  opacity: 0;
  transition: opacity 200ms linear;
}

.EditItem__footer-changes-circle {
  width: 25px;
  height: 25px;
  background-color: #68cea2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.EditItem__saving-button-spin {
  transform: translateY(3px);
}

.EditItem__saving-button-spin > div > svg {
  animation: spin 800ms infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.EditItem__print-barcode {
  margin-right: -26px;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
}
