.SettingsAccess {
}

.SettingsAccess__title {
  position: absolute;
  top: -57px;
}

.SettingsAccess__title-text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.SettingsAccess__item {
  padding: 20px;
  border-bottom: 1px solid #a1b4c4;
}

.SettingsAccess__inventory {
  border-bottom: none;
}

.SettingsAccess__checkbox {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
}

.UserSettings__content::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.UserSettings__content::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}
