.OrdersAddItem {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.OrdersAddItem__title {
  padding: 0 95px;
  padding-top: 109px;
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
}

.OrdersAddItem__search {
  padding: 0 95px;
  padding-top: 33px;
  display: flex;
  width: 100%;
}

.OrdersAddItem__button {
  border: 1px solid #a1b4c4;
  height: 42px;
  width: 50px;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  margin-left: 6px;
  position: relative;
}

.OrdersAddItem__camera-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.OrdersAddItem__scanner-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.OrdersAddItem__buttonLabel {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6d7a85;
  font-size: 11px;
  opacity: 0.7;
}

.OrdersAddItem__added-items {
  padding: 0 95px;
  margin-top: 66px;
  padding-bottom: 10px;
  overflow-y: auto;
}

.OrdersAddItem__added-items::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.OrdersAddItem__added-items::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.OrdersAddItem__add-button {
  position: absolute;
  right: 95px;
  width: 30%;
  margin-top: 20px;
}

.OrdersAddItem__add-button-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  z-index: 11;
  left: 15px;
}

@media (min-height: 900px) {
  .OrdersAddItem__add-button {
    bottom: 20px;
  }
}
