.SuperAdminNewHoliday {
  padding: 0 95px;
}

.SuperAdminNewHoliday__value {
  justify-self: flex-end;
  margin-left: auto;
  color: #0c2c52;
  display: flex;
}

.SuperAdminNewHoliday__value > span > div {
  padding: 10px;
  border-radius: 10px;
}

.SuperAdminNewHoliday__value > .react-tooltip-lite-arrow {
  display: none;
}
