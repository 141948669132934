.PrintBarcode {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* PRINTABLE BARCODE */

.PrintableBarcode {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  height: 36mm;
  padding: 18px;
  margin: 3px;
  size: landscape;
}

.PrintableBarcode__name {
  overflow: hidden;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3rem;
  text-overflow: clip;
  white-space: nowrap;
}

.PrintableBarcode__content {
  display: flex;
  flex-direction: row;
  width: 89mm;
}

.PrintableBarcode__subcontent {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.PrintableBarcode__prodNo {
  font-size: 0.7rem;
}

.PrintableBarcode__company {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  /* justify-self: flex-end; */
  /* margin-left: auto; */
  display: flex;
  justify-content: flex-end;
}

.PrintableBarcode__barcode {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
}

.PrintBarcode__print-icon {
  display: flex;
  justify-content: center;
}

.iconPosition-left {
  display: flex;
  flex-direction: row;
}

.iconPosition-left > div {
  margin-right: 8px;
}

.iconPosition-left > div > div > svg > path {
  fill: #a1b4c4;
}
