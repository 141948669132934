.OrderItem__amount {
  width: 20%;
}

.OrderItem__amount-text {
  margin-right: 2px;
}

.OrderItem__item {
  display: grid;
  grid-template-columns: 58px 3fr 1fr 168px 53px;
  height: 55px;
  border-top: 1px solid #a1b4c4;
  align-items: center;
  width: 100%;
  color: #6d7a85;
}

.OrderItem__item-view-tooltip {
  height: 100%;
}

.OrderItem__item-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.OrderItem__item-view:hover > div > svg > path {
  fill: #4092d7;
  transition: all 100ms linear;
}

.OrderItem__item-id {
  color: #6d7a85;
  font-size: 15px;
}

.OrderItem__item-name {
  color: #0c2c52;
  font-size: 15px;
  line-height: 1.2em;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.OrderItem__item-description {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.OrderItem__item-amount {
  text-align: right;
  justify-self: flex-end;
  margin-left: auto;
}

.OrderItem__item-addToInv {
  justify-self: flex-end;
  height: 39px;
}

.OrderItem__item-addtoinv-button {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  height: 37px;
  color: #4092d7;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: bold;
}

.OrderItem__item-checkbox {
  display: flex;
  justify-content: flex-end;
  margin-right: 17px;
}

.OrderItem__amount-readonly {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.OrderItem__amount__icon {
  margin-left: 6px;
}

.OrderItem__amount-units {
  opacity: 0.4;
  color: #0c2c52;
  font-size: 15px;
  line-height: 1.2em;
  width: 26px;
}

.OrderItem__amount-editing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.OrderItem__units-absolute {
  position: absolute;
  right: 60px;
  z-index: 100;
  transform: translateY(-1px);
  opacity: 0.4;
  color: #0c2c52;
  font-size: 15px;
  line-height: 1.2em;
}

.OrderItem__amount-input {
  padding: 9px 37px 9px 0;
  outline: none;
  border: none;
  color: #0c2c52;
  font-size: 15px;
  line-height: 1.2em;
  text-align: right;
  background-color: rgba(161, 180, 196, 0.2);
  border: 1px solid #a1b4c4;
  border-radius: 4px 0 0 4px;
  width: 67px;
  height: 19px;
}

.OrderItem__amount-confirm {
  border: 1px solid #a1b4c4;
  border-radius: 0 4px 4px 0;
  background-color: rgba(255, 255, 255, 0.2);
  color: #4092d7;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: bold;
  height: 39px;
  width: 51px;
  border-left: none;
}

.OrderItem__item-isAdded {
  display: flex;
  align-items: center;
  width: 139px;
  height: 37px;
}

.OrderItem__item-isAdded__text {
  color: #6d7a85;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  white-space: nowrap;
}

@media (min-width: 1023px) {
  .OrderItem__item {
    grid-template-columns: 49px 58px 1fr 1fr 156px 180px 73px;
  }
  .OrderItem__item-id {
    margin-left: 0px;
  }
}

.OrderItem__item--backorder {
  background-color: #c5d1db;
}
