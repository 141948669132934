.Select {
  position: relative;
  display: inline-block;
}

.Select--disabled > .Select__element > .Select__value {
  cursor: not-allowed;
  background-color: #f1f4f6;
  border-radius: 5px;
  opacity: 0.4;
}

.Select__value {
  cursor: pointer;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Select__options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 700px;
  overflow-y: auto;
  display: none;
  background-color: #fff;
  border: 1px solid #a1b4c4;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.Select__element {
  width: 100%;
}

.Select__icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -55%);
  transition: 250ms transform ease-out 50ms;
  pointer-events: none;
}

.SelectOption {
  cursor: pointer;
  width: auto;
  height: 40px;
}

.Select.is-open .Select__options {
  display: block;
  overflow: hidden;
}

/* Default */

.Select--default {
  color: #0c2c52;
  width: 90px;
  font-weight: bold;
}

.Select--default .Select__value {
  height: 16px;
  line-height: 16px;
  font-size: 13px;
  padding: 0 12px;
  transition: 250ms background-color ease-out;
}

.Select--default .Select__options {
  /* top: -4px; */
  font-size: 13px;
  border-radius: 5px;
  left: 6px;
  width: 105px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.Select--default .Select__options:before {
  height: 16px;
}

.Select--default.is-open .Select__options {
  font-weight: normal;
}

.Select--default .SelectOption {
  padding: 0 12px;
  height: 30px;
  line-height: 28px;
  background-color: #fff;
  transition: 250ms background-color ease-out;
  border-bottom: 1px solid #d3d7dd;
}

.Select--default .SelectOption:hover {
  background-color: rgba(65, 147, 214, 0.4);
}

.Select--default .SelectOption.is-selected {
  font-weight: bold;
  color: #4092d7;
}

/* EditItem */

.Select--editItem {
  color: #0c2c52;
  width: 100%;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  text-align: right;
}

.Select--editItem .Select__value {
  line-height: 37px;
  font-size: 15px;
  padding: 0 24px;
  transition: 250ms background-color ease-out;
}

.Select--editItem .Select__options {
  font-size: 13px;
  transform: translateY(37px);
  border-radius: 4px;
  font-size: 15px;
  max-height: 250px;
  overflow-y: auto !important;
}

.Select--editItem .Select__options:before {
  height: 16px;
}

.Select--editItem .SelectOption {
  padding: 0 12px;
  line-height: 37px;
  background-color: #fff;
  transition: 250ms background-color ease-out;
  border-bottom: 1px solid #d3d7dd;
}

.Select--editItem .SelectOption:hover {
  background-color: rgba(64, 146, 215, 0.1);
}

.Select--editItem .SelectOption.is-selected {
  font-weight: bold;
  color: #4092d7;
}
