.AddNewItemsItem {
  display: grid;
  grid-template-columns: 1fr calc(58px + 36px) calc(58px + 36px) 200px 20px;
  gap: 1rem;
  height: 53px;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.ScanItemInModalItem__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
  font-size: 15px;
  font-weight: bold;
}

.ScanItemInModalItem__id {
  font-size: 11px;
}
