.Checkbox {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  width: 21px;
  height: 21px;
  transition: background-color 0.2s linear;
}

.Checkbox__box {
  background-color: #4092d7;
  transition: background-color 0.2s linear;
  width: 100%;
  height: 100%;
}

.Checkbox__checked {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #4092d7;
}

.Checkbox__partiallyChecked {
  height: 100%;
  width: 100%;
  background-color: #4092d7;
  border-color: #4092d7;
}
