.ShowMoreButton {
  border: none;
  outline: none;
  background-color: #fff;
  border: 1px solid #6d7a85;
  border-radius: 4px;
  padding: 8px 36px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: #0c2c52;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}

.ShowMoreButton:hover {
  background-color: rgba(216, 216, 216, 0.2);
}
