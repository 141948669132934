.ListItemActions {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 10px 0 0;
}

.ListItemActions__action {
  display: inline-block;
  border-radius: 0 10px 0 0;
}

.ListItemActions__icon {
  display: inline-block;
  padding-right: 0px;
}

.ListItemActions__text {
  display: inline-block;
  font-size: 15px;
  color: #4092D7;
  transform: translateY(-1px);
}

@media(min-width: 1024px) {
  .ListItemActions__icon {
    padding-right: 8px;
  }
}