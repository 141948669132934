.DashboardPopOutMenu {
  background-color: #fff;
  position: absolute;
  right: 39px;
  top: 36px;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  box-shadow: 2px 3px 7px 0 rgba(0, 0, 0, 0.1);
  z-index: 200;
  pointer-events: auto;
}

.DashboardPopOutMenu:last-child {
  border-bottom: none;
}

.DashboardPopOutMenuItem {
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  transition: background-color 200ms ease-in-out;
}

.DashboardPopOutMenuItem:hover {
  cursor: pointer;
  background-color: rgba(161, 180, 196, 0.2);
}

.DashboardPopOutMenuItem__name {
  font-size: 14px;
  color: #0c2c52;
  font-weight: 700;
  line-height: 17px;
  margin-left: 15px;
  pointer-events: none;
}
