.OrdersDateChangedBanner {
  top: 0;
  background-color: #4092d7;
  height: 52px;
  left: 90px;
  right: 0;
  z-index: 200;
}

.OrdersDateChangedBanner__title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 38px;
}

.OrdersDateChangedBanner__title > div {
  transform: translateY(-1px);
}

.OrdersDateChangedBanner__title-text {
  color: #fff;
  font-weight: 700;
}

.OrdersDateChangedBanner__icon {
  margin-right: 8px;
}

.OrdersDateChangedBanner__icon > div > svg > g > circle {
  fill: #fff;
}

.OrdersDateChangedBanner__icon > div > svg > g > path {
  fill: #4092d7;
}

.OrdersDateChangedBanner__icon > div > svg > g > rect {
  fill: #4092d7;
}
