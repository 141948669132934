.Dashboard {
  padding: 60px 28px 0 38px;
  display: grid;
  grid-template-rows: 1.3fr 1fr 1fr 30px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  overflow-y: auto;
  height: 100%;
  margin-bottom: 20px;
}

.Dashboard::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.Dashboard::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.Dashboard__inventory-value {
  grid-column-start: 1;
  grid-column-end: 4;
}

.Dashboard__stale-items {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.Dashboard__buys {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.Dashboard__totals {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.Dashboard__calendar {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
}

/* ### DASHBOARD TITLE ### */
.DashboardTitle {
  position: absolute;
  top: -57px;
  left: 38px;
}

.DashboardTitle__text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.TopDividerBar {
  height: 7px;
  width: 100%;
  border-bottom: 1px solid #a1b4c4;
  background-color: #d9e1e7;
  margin-left: 38px;
  z-index: 50;
  position: relative;
  transition: all 200ms ease-in-out;
}
