.OrderButton {
  width: 138px;
  height: 37px;
  border: 1px solid #a1b4c4;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.OrderButton__orderButton {
  background-color: #68cea2;
}

.OrderButton__label {
  padding-left: 4px;
  color: #ffffff;
  font-size: 15px;
}

.OrderButton__fragment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.OrderButton__spinning {
  animation: spin 800ms infinite linear;
}

.OrderButton__disabled {
  background-color: #a1b4c4;
  cursor: default;
}
