.SettingsAdminContent {
  background-color: #fff;
  border: 1px solid #a1b4c4;
  border-radius: 10px 10px 0 0;
}

.SettingsAdmin__saved {
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: 25px;
}

.SettingsAdmin__actions {
  height: 70px;
  display: flex;
  background-color: rgba(161, 180, 196, 0.2);
  border-radius: 0 0 10px 10px;
  border: 1px solid #a1b4c4;
  align-items: center;
  padding: 0 20px;
  border-top: none;
}

.CustomDateInput {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  color: #0c2c52;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
  padding: 7px;
}

.CustomDisabledDateInput {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  color: #0c2c52;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
  padding: 7px;
  opacity: 0.7;
  margin-left: 16px;
}

/* CALENDAR OVERWRITES */

.react-datepicker__month-container {
  padding: 25px;
}

.react-datepicker__header--custom {
  background-color: #fff !important;
  border-bottom: none !important;
}

.react-datepicker__day-name {
  color: #6d7a85;
  text-decoration: none;
  font-weight: 700;
}

.react-datepicker__day {
  transition: all 200ms ease-in-out !important;
  font-size: 14px;
  border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
  color: #949ba1 !important;
}

.react-datepicker__day--selected {
  border-radius: 50% !important;
}

.react-datepicker__day:hover {
  background-color: rgba(64, 146, 215, 0.2) !important;
}

.SettingsAdmin__calendar {
  background-color: #ffffff;
}

.CalendarHeader {
  height: 44px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.CalendarHeader__month {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
  justify-self: center;
  margin: 0 auto;
}

.CalendarHeader__back-button {
  cursor: pointer;
  justify-self: flex-start;
  margin-left: 12px;
}

.CalendarHeader__next-button {
  cursor: pointer;
  justify-self: flex-end;
  /* margin-left: auto; */
  margin-right: 12px;
}
