.SettingsMenuItem {
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #a1b4c4;
  transition: all 350ms ease-in-out;
  cursor: pointer;
  position: relative;
}

.SettingsMenuItem:hover {
  background-color: rgb(247, 247, 247);
  transition: all 350ms ease-in-out;
}

.SettingsMenuItem__title {
  padding-left: 20px;
}

.SettingsMenuItem__title-text {
  font-size: 15px;
  font-weight: 700;
}

.SettingsMenuItem__active {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #4092d7;
}
