.ScanCardInactive {
  position: relative;
  border: 1px solid #A1B4C4;
  border-radius: 10px;
  height: 100%;
  opacity: 0.89;
  background-color: rgba(161,180,196,0.3);
  text-align: center;
  color: #808F9C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end !important;
}

.ScanCardInactive__logo {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.ScanCardInactive__logo > div {
  margin-top: auto;
}

.ScanCardInactive__title {
  flex: 3;
  font-size: 25px;
  font-weight: 300;
  line-height: 30px;
  max-width: 221px;
}
.ScanCardInactive__subtitle {
  text-shadow: 1px 1px 2px 0 rgba(0,0,0,0.1);
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  padding-top: 43px;
}

/* .ScanCardInactive__logo {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.ScanCardInactive__logo > div {
  margin: auto;
}

.ScanCardInactive__title {
  padding-top: 29px;
  font-size: 25px;
  font-weight: 300;
  line-height: 30px;
  text-shadow: 1px 1px 2px 0 rgba(0,0,0,0.1);
  max-width: 221px;
  margin: auto;
  padding-top: 236px;
}

.ScanCardInactive__subtitle {
  text-shadow: 1px 1px 2px 0 rgba(0,0,0,0.1);
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  max-width: 221px;
  margin: auto;
  padding-top: 43px;
} */