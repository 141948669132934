.RemoveOrderItem__shadow {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 70;
}

.RemoveOrderItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #d8d8d8;
  z-index: 100;
  height: 440px;
  width: 600px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.RemoveOrderItem__exit {
  position: absolute;
  top: 21px;
  right: 21px;
  display: flex;
  cursor: pointer;
}

.RemoveOrderItem__text {
  line-height: 36px;
  margin-right: 8px;
  opacity: 0.7;
  color: #6d7a85;
  font-size: 12px;
  font-weight: bold;
}

.RemoveOrderItem__icon {
  border-radius: 50%;
  border: 1px solid rgba(109, 122, 133, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  transition: border 150ms linear;
}

.RemoveOrderItem__icon:hover {
  border: 1px solid rgba(109, 122, 133, 1);
  transition: border 150ms linear;
}

.RemoveOrderItem__title {
  padding-top: 107px;
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  max-width: 353px;
  margin: auto;
  text-align: center;
}

.RemoveOrderItem__sub-title {
  padding-top: 40px;
  color: #4092d7;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  max-width: 353px;
  margin: auto;
}

.RemoveOrderItem__actions {
  height: 85px;
  position: absolute;
  bottom: -1px;
  width: 100%;
  display: flex;
  border-top: 1px solid #d8d8d8;
}

.RemoveOrderItem__cancel {
  flex: 1;
  min-width: 300px;
}

.RemoveOrderItem__confirm {
  flex: 1;
  min-width: 299px;
}
.RemoveOrderItem__confirm > div {
  background-color: #4092d7 !important;
}
