.DashboardOrderCalendarMenu {
  position: absolute;
  right: 25px;
  top: 19px;
  display: flex;
  align-items: center;
  padding: 8px;
  white-space: nowrap;
}

.DashboardOrderCalendarMenu:hover {
  cursor: pointer;
}
.DashboardOrderCalendarMenu:hover > .DashboardOrderCalendarMenu__menu-point {
  background-color: #a1b4c4;
}
.DashboardOrderCalendarMenu:hover
  > .DashboardOrderCalendarMenu__menu-point
  > div.Icon
  > svg
  > g {
  fill: #fff;
}

.DashboardOrderCalendarMenu__menu-point {
  width: 11px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 3px;
  transition: all 100ms ease-in-out;
}

.DashboardOrderCalendarMenu__month {
  font-size: 13px;
  font-weight: 700;
  color: #6d7a85;
}
