.AddNewItems {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}

.AddNewItems__scannedItems {
  overflow: auto;
}

.AddNewItems__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 840px;
  height: 560px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
}

.AddNewItems__header {
  height: 24%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
}

.AddNewItems__exit {
  position: absolute;
  right: 21px;
  top: 21px;
}

.AddNewItems__title {
  padding-bottom: 36px;
  align-self: flex-end;
  font-size: 25px;
  font-weight: bold;
}

.AddNewItems__content {
  height: 301px;
  border-bottom: 1px solid #d8d8d8;
}

.AddNewItems__static-area {
  display: grid;
  grid-template-columns: 1fr calc(58px + 36px) calc(58px + 36px) 200px 20px;
  gap: 1rem;
  place-items: end;
  height: 58px;
  margin: 0 45px;
}

.AddNewItems__static-text {
  opacity: 0.7;
  color: #6d7a85;
  font-size: 11px;
  padding-bottom: 13px;
}

.AddNewItems__autoreorder {
  margin-right: 4px;
}

.AddNewItems__items {
  height: 215px;
  border-top: 1px solid #d8d8d8;
  margin: 0 45px;
}

.AddNewItems__camera {
  display: flex;
  align-items: center;
}

.AddNewItems__camera-text {
  color: #4092d7;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.AddNewItems__information-area {
  margin: 0 87px 0 55px;
  display: flex;
  height: 40px;
  align-items: center;
}

.AddNewItems__scannedAmount {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.AddNewItems__scannedAmount-text {
  color: #6d7a85;
  opacity: 0.7;
  font-size: 12px;
  font-weight: bold;
}

.AddNewItems__scannedAmount-bar {
  height: 11px;
  width: 100px;
  margin-left: 9px;
}

.AddNewItems__actions {
  width: 100%;
  height: 85px;
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #d8d8d8;
}

.AddNewItems__error-button {
  width: 50%;
  background-color: #aa2525;
  color: #ffffff;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
}

.AddNewItems__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  opacity: 0.9;
}

.AddItemsActionButton--Cancel {
  background-color: #f5f7f9;
  color: #a1b4c4;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
}

.AddItemsActionButton--Action {
  background-color: #68cea2;
  color: #ffffff;
  text-align: center;
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
}

.AddNewItems__Item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
  background-color: #fff;
  padding-inline: 45px;
}

.AddNewItems__BarcodePrint {
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: #d8d8d8;
}

.AddNewItems__Item > .PrintBarcode {
  width: unset;
}
