.SuperAdminNewAccount {
  padding: 0 95px;
}

.SuperAdminNewAccount__header {
  padding-top: 109px;
  padding-bottom: 45px;
}

.SuperAdminNewAccount__title {
  font-size: 25px;
  color: #0c2c52;
  font-weight: 700;
}

.SuperAdminNewAccount__dividerbar {
  width: 100%;
  border-top: 1px solid #a1b4c4;
}

.SuperAdminNewAccount__item {
  height: 53px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a1b4c4;
}

.SuperAdminNewAccount__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 140px;
  background-color: rgba(161, 180, 196, 0.2);
  border-top: 1px solid #a1b4c4;
}

.SuperAdminNewAccount__button {
  position: absolute;
  right: 95px;
  top: 20px;
}

.SuperAdminNewAccount__checkbox {
  height: 100%;
  justify-self: flex-end;
  display: flex;
  color: #0c2c52;
  margin-left: auto;
}

.SuperAdminNewAccount__seperator {
  padding-bottom: 53px;
}
