@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.OrderListItem {
  height: 55px;
  border-bottom: 1px solid #a1b4c4;
  background-color: #fff;
  animation-name: fadeInAnimation;
  animation-duration: 200ms;
}

.OrderListItem__content {
  display: grid;
  grid-template-columns: minmax(0px, 58px) 2fr minmax(0px, 30px) minmax(0px, 30px) minmax(0px, 40px) minmax(0px, 175px);
  width: 100%;
  height: 100%;
  color: #6d7a85;
  max-width: 100%;
  min-width: 0;
}

.OrderListItem__content > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.OrderListItem__item-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.OrderListItem__id {
  color: #6d7a85;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.OrderListItem__name {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0c2c52;
  max-height: 55px;
}

.OrderListItem__name-formatted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 98%;
}

.OrderListItem__description {
  display: flex;
  align-items: center;
  color: #6d7a85;
  font-size: 15px;
  letter-spacing: 0.47px;
}

.OrderListItem__delivery-extra {
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrderListItem__added-how {
  display: flex;
  align-items: center;
  justify-self: center;
}

.OrderListItem__delete {
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  width: 50px;
  height: 100%;
}

.OrderListItem__delete:hover > div > svg > path {
  fill: #4092d7;
}

.OrderListItem__amount {
  height: 41px;
  margin-left: 10px;
  width: 170px;
  align-self: center;
}

.OrderListItem__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.OrderListItem__image:hover > div > svg > path {
  fill: #4092d7;
  transition: all 100ms linear;
}

@media (min-width: 1023px) {
  .OrderListItem__content {
    grid-template-columns: 49px 58px 2fr 2fr 30px 30px 40px 190px;
  }

  .OrderListItem__id {
    margin-left: 0px;
  }
}

@media (min-width: 1299px) {
  .OrderListItem__content {
    grid-template-columns: 49px 58px 2fr 2fr 198px 135px 70px 190px;
  }
}
