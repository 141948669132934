.SplitSelect {
  height: 100%;
  border: 1px solid #A1B4C4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  width: 160px;
}

.SplitSelect__label {
  padding-left: 15px;
  padding-right: 2px;
  color: #4092D7;
  font-size: 14px;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex: 4;
}

.SplitSelect__label:hover {
  background-color: rgba(64, 146, 215, 0.2);
}

.SplitSelect__label-text {
  align-self: center;
}

.SplitSelect__arrow {
  width: 30px;
  height: 100%;
  position: relative;
  justify-self: flex-end;
  margin-left: auto;
}

.SplitSelect__arrow:hover {
  background-color: rgba(64, 146, 215, 0.2);
}

.SplitSelect__options {
  position: absolute;
  top: 35px;
  z-index: 50;
  background-color: #fff;
  border: 1px solid #A1B4C4;
  border-radius: 4px;
  min-width: 158px;
}

.SplitSelect__option {
  border-top: 1px solid #A1B4C4;
  padding-left: 15px;
  line-height: 39px;
  color: #4092D7;
  font-weight: bold;
  font-size: 14px;
}

.SplitSelect__option:hover {
  background-color: rgba(64, 146, 215, 0.2);
}