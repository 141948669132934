.SearchBar {
  height: 100%;
}

.SearchBar__input {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: rgba(161, 180, 196, 0.5);
  font-size: 17px;
  line-height: 20px;
  padding: 11px 20px;
  transition: all 0.2s ease-in-out;
  box-sizing: inherit;
}

.SearchBar__input:focus {
  background-color: #fff;
  color: #000;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.SearchBar__input::placeholder {
  color: #0c2c52;
  font-size: 17px;
  opacity: 1;
  /* transform: translateY(-2px); */
}

/* Icon is irregular, therefor we translate only 45%, so it's visually aligned. */
.SearchBar__icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-45%);
  pointer-events: none;
}

.SearchBar__icon-clickable {
  pointer-events: all;
  cursor: pointer;
}

.SearchBar__icon > div > svg > path {
  fill: #0c2c52;
}
