.OrderDisplayBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: -90px;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.7);
}

.OrderDisplay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 901;
  width: 700px;
  background-color: #fff;
}
.OrderDisplayOrder__exit {
  position: absolute;
  top: 39px;
  right: 39px;
}

.OrderDisplayOrder {
  padding: 109px 95px 100px 95px;
  max-height: 100vh;
  overflow-y: scroll;
}

.OrderDisplayOrder::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.OrderDisplayOrder::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.OrderDisplayOrder__header {
  margin-bottom: 33px;
}

.OrderDisplayOrder__header--text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.OrderDisplayOrderLine {
  display: flex;
  align-items: center;
  height: 101px;
  border-top: 1px solid #d8d8d8;
}

.OrderDisplayOrderLine:last-child {
  border-bottom: 1px solid #d8d8d8;
}

.OrderDisplayOrderLine__image {
  width: 71px;
  height: 71px;
  border: 1px solid #a1b4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}

.OrderDisplayOrderLine__information--name {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.OrderDisplayOrderLine__information--sub {
  color: #6d7a85;
  font-size: 15px;
  line-height: 19px;
}

.OrderDisplayOrderLine__amount {
  justify-self: flex-end;
  margin-left: auto;
  color: #6d7a85;
  font-size: 15px;
  line-height: 19px;
}
