.OrderNewItem {
  display: grid;
  grid-template-columns: minmax(71px, 71px) 3fr minmax(170px, 1fr);
  gap: 10px;
  position: relative;
  min-height: 102px;
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 0;
  transition: max-height 400ms ease-in-out;
}

.OrderNewItem:last-child {
  border-bottom: none;
}

.OrderNewItem__expand {
  position: absolute;
  left: -24px;
  top: 42px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.OrderNewItem__image {
  position: relative;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  overflow: hidden;
  height: 65px;
  width: 65px;
}

.OrderNewItem__image-tag {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.OrderNewItem__item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0px;
}

.OrderNewItem__name {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.OrderNewItem__id {
  color: #6d7a85;
  font-size: 15px;
  line-height: 19px;
}

.OrderNewItem__description {
  color: #6d7a85;
  font-size: 15px;
  line-height: 19px;
  max-width: 95%;
}

.OrderNewItem__description-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.OrderNewItem__number-input {
  justify-self: flex-end;
  margin-left: auto;
  z-index: 20;
  margin-top: 16px;
}

.OrderNewItem__icon {
  justify-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  position: absolute;
  right: -30px;
  top: 42px;
}

.OrderNewItem__read-more {
  margin-top: 2px;
  color: #6d7a85;
  font-size: 13px;
}
