.SuperAdminHolidays {
  padding: 60px 70px;
}

.SuperAdminHolidays__header {
}

.SuperAdminHolidays__title {
  font-size: 25px;
  color: #0c2c52;
  font-weight: 700;
  position: absolute;
  top: -57px;
}

.SuperAdminHolidays__dividerbar {
  width: 100%;
  border-top: 1px solid #a1b4c4;
}

.SuperAdminHolidays__content {
  padding-top: 7px;
}

.SuperAdmin__newholiday {
  position: absolute;
  right: 70px;
  top: 22px;
}
