.InventoryListItem {
  display: block;
  width: 100%;
  height: 55px;
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
  background-color: transparent;
  border-bottom: 1px solid #a1b4c4;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.InventoryListItem__content {
  display: grid;
  grid-template-columns: 58px 2fr 40px 70px 45px 66px;
  width: 100%;
  height: 100%;
  vertical-align: top;
  position: relative;
  cursor: pointer;
}

.InventoryListItem__content > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.InventoryListItem__image-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.IntentoryListItem__hover-trigger {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 49px;
  height: 54px;
  pointer-events: auto;
}

.InventoryListItem__image-icon:hover > div > svg > path {
  fill: #4092d7;
  transition: all 100ms linear;
}

.InventoryListItem__id {
  display: flex;
  align-items: center;
  margin-left: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
}

.InventoryListItem__name {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 10px;
}

.InventoryListItem__details {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.disabledAutomaticOrdering > svg > g > circle {
  stroke: #a1b4c4;
}
.disabledAutomaticOrdering > svg > g > rect {
  fill: #a1b4c4;
}

.InventoryListItem__text-formatted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.InventoryListItem__status {
  display: flex;
  align-items: center;
  color: #9be729;
  font-weight: 600;
}

.InventoryListItem__stock-amount {
  display: flex;
  align-items: center;
  padding-right: 6px;
  justify-content: flex-end;
}

.InventoryListItem__bar {
  display: flex;
  align-self: center;
  height: 8px;
}

.InventoryListItem__refresh {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InventoryListItem__actions {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: rgba(161, 180, 196, 0.15);
  border-left: 1px solid #a1b4c4;
  pointer-events: auto;
}

.InventoryListItem:hover {
  background-color: rgba(161, 180, 196, 0.2);
  transition: all 150ms linear;
}

@media (min-width: 1023px) {
  .InventoryListItem__content {
    display: grid;
    grid-template-columns: 49px 58px 2fr 2fr 140px 70px 100px 45px 146px;
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: relative;
  }
  .InventoryListItem__id {
    margin-left: 0;
  }
  .InventoryListItem__bar {
    height: 11px;
  }
}
