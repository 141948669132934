.NoWatchedProducts {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.NoWatchedProducts__title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 7px;
}

.NoWatchedProducts__title-text {
  color: #a1b4c4;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-weight: 700;
}

.NoWatchedProducts__status {
  margin-top: 7px;
}

.NoWatchedProducts__status-text {
  line-height: 15px;
  font-size: 12px;
  color: #d69d23;
  font-weight: 700;
}

@keyframes sweeping {
  0% {
    transform: translateX(90px) translateY(-15px);
  }

  5% {
    transform: translateX(85px) translateY(-10px);
  }

  7.5% {
    transform: translateX(85px) translateY(-10px) scale(1.1);
  }

  10% {
    transform: translateX(85px) translateY(-10px);
  }

  15% {
    transform: translateX(55px) translateY(10px);
  }
  17.5% {
    transform: translateX(55px) translateY(10px) scale(1.1);
  }

  20% {
    transform: translateX(55px) translateY(10px);
  }

  25% {
    transform: translateX(25px) translateY(0px);
  }

  27.5% {
    transform: translateX(25px) translateY(0px) scale(1.1);
  }

  30% {
    transform: translateX(25px) translateY(0px);
  }

  40% {
    transform: translateX(90px) translateY(-15px);
  }

  100% {
    transform: translateX(90px) translateY(-15px);
  }
}

.AnimatedWatcherIcon__magnifyingglass {
  animation: sweeping 10000ms ease-in-out infinite;
  width: 40px;
  height: 40px;
  z-index: 800;
  display: flex;
  transform: translateX(95px) translateY(-15px);
}

.AnimatedWatcherIcon__magni__cicles {
  height: 36px;
  width: 36px;
  position: absolute;
  /* right: -40px;
  top: -10px; */
}

.AnimatedWatcherIcon__magni-circle1 {
  background-color: transparent;
  width: 40px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 4px solid rgb(245, 247, 249); */
}
.AnimatedWatcherIcon__magni-circle2 {
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 4px solid rgb(161, 180, 196); */
  border: 4px solid rgb(205, 212, 218);
}

.AnimatedWatcherIcon__magni-circle-sticky-boi {
  position: absolute;
  width: 14px;
  height: 4px;
  box-sizing: border-box;
  background: rgb(205, 212, 218);
  transform: translateX(30px) translateY(-6px) rotateZ(45deg);
}

.AnimatedWatcherIcon {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateX(-20px);
}

.AnimatedWatcherIcon__content {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  position: relative;
}

.AnimatedWatcherIcon__Box {
  background-color: rgb(161, 180, 196);
  border-radius: 2px;
  height: 35px;
  width: 20px;
  margin-right: 4px;
  z-index: 100;
}

@keyframes bouncybois {
  0% {
    transform: translateY(0px);
  }
  16% {
    transform: translateY(-3px);
  }
  33% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

.NoWatchedProducts__status-text--dot {
  animation: bouncybois 1500ms linear infinite;
  display: inline-block;
}

.NoWatchedProducts__status-text--dot1 {
  animation-delay: 0ms;
}

.NoWatchedProducts__status-text--dot2 {
  animation-delay: 200ms;
}

.NoWatchedProducts__status-text--dot3 {
  animation-delay: 400ms;
}
