.ExportDatepicker {
}

.ExportDatepicker__calendar {
  padding: 25px;
}

.ExportDatepicker__actions-wrapper {
  display: flex;
}

.ExportDatepicker__actions-wrapper .ExportDatepicker__action-button:first-child {
  border-bottom-left-radius: 4px;
}

.ExportDatepicker__actions-wrapper .ExportDatepicker__action-button:last-child {
  border-bottom-right-radius: 4px;
}

.ExportDatepicker__action-button {
  margin-top: 12px;
  height: 46px;
  width: 100%;
  background-color: #4092d7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  cursor: pointer;
}

.ExportDatepicker__action-button:hover {
  background-color: #06599e;
}

.ExportDatepicker__button {
  color: #fff;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
}

.ExportDatepicker__button > div > svg > * {
  fill: #fff;
}

.ExportDatepicker__button-text {
  color: #fff;
  margin-left: 8px;
  font-size: 15px;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ExportDatepicker__button > .ExportDatepicker__loader {
  animation: loadingSpinner 800ms infinite linear;
}

/* REACT-CALENDAR OVERWRITES */
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays__weekday {
  color: #6d7a85;
  text-decoration: none;
  font-weight: 700;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar__tile {
  position: relative;
}

.react-calendar__month-view__days__day--neighboringMonth
  > .ExportDatepicker__calendar-tile-highlighter
  > .ExportDatepicker__calendar-tile-text {
  color: #949ba1;
}

.react-calendar__tile:disabled {
  background-color: #fff;
}

.react-calendar__tile:disabled .ExportDatepicker__calendar-tile-text {
  color: #dedede;
}

.react-calendar__tile--active {
  background: transparent;
}

.react-calendar__tile--active .ExportDatepicker__calendar-tile-highlighter {
  height: 32px;
  width: 32px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background: #4092d7;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-in-out;
}

.react-calendar__tile--active .ExportDatepicker__calendar-tile-text {
  color: #fff;
  font-weight: 700;
}

.react-calendar button > abbr {
  display: none;
}

.react-calendar__tile:enabled:hover {
  background: transparent;
}
.react-calendar__tile:enabled:focus {
  background: transparent;
}

.react-calendar__tile:hover > .ExportDatepicker__calendar-tile-highlighter {
  height: 32px;
  width: 32px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: rgba(64, 146, 215, 0.2);
  /* border: 1px solid #4092d7; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-in-out;
}

.ExportDatepicker__calendar-tile-text {
  transform: translateY(-1px);
  color: #0c2c52;
  font-size: 14px;
  opacity: 1;
}

.CalendarLabel {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4092d7;
  background-color: #fff;
  transition: background-color 200ms ease-in-out;
  border-radius: 50%;
}

.CalendarLabel:hover {
  background-color: #4092d7;
}

.react-calendar__navigation__arrow {
  background: transparent;
  opacity: 1;
}

.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background: transparent;
}

.react-calendar__navigation button[disabled] {
  background: transparent;
}

.CalendarNextLabel {
  transform: translateX(1px);
}

.CalendarNextLabel > div > svg {
  transform: rotate(-90deg) translateY(1px);
}

.CalendarNextLabel > div > svg > path {
  fill: #4092d7;
  opacity: 1;
}

.CalendarPrevLabel {
  transform: translateX(-1px);
}

.CalendarPrevLabel > div > svg {
  transform: rotate(90deg) translateY(1px);
}

.CalendarPrevLabel > div > svg > path {
  fill: #4092d7;
  opacity: 1;
}

.CalendarLabel:hover > div > svg > path {
  fill: #fff;
}

.CalendarLabel:hover > div > svg > path {
  fill: #fff;
}

.react-calendar__navigation button[disabled] > .CalendarLabel {
  border: 1px solid #a1b4c4;
}

.react-calendar__navigation button[disabled] > .CalendarLabel:hover {
  border: 1px solid #a1b4c4;
  background-color: #a1b4c4;
}

.react-calendar__navigation
  button[disabled]
  > .CalendarLabel
  > div
  > svg
  > path {
  fill: #a1b4c4;
}

.react-calendar__navigation
  button[disabled]
  > .CalendarLabel:hover
  > div
  > svg
  > path {
  fill: #fff;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  overflow: hidden;
}

.react-calendar__navigation__label {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
}

.react-calendar {
  border: none;
}

.react-calendar__month-view > div > div {
  border-radius: 10px;
}

.react-calendar {
  /* width: 260px !important; */
}

.react-calendar__tile:disabled:hover
  > .ExportDatepicker__calendar-tile-highlighter {
  background-color: transparent;
}
