.InactiveOrderDetails {
  height: 100%;
}

.InactiveOrderDetails__item {
  border: 1px solid #a1b4c4;
  border-bottom: none;
  display: grid;
  grid-template-columns: 58px 1fr 0px 178px;
  height: 55px;
  border-top: 1px solid #a1b4c4;
  align-items: center;
  width: 100%;
  height: 55px;
  color: #6d7a85;
  background-color: #c5d1db;
}

.InactiveOrderDetails__item:last-child {
  border-radius: 0 0 10px 10px;
}

.InactiveOrderDetails__item:last-child {
  border-bottom: 1px solid #a1b4c4;
}
.InactiveOrderDetailsItem__loading:last-child {
  border-bottom: 1px solid #a1b4c4;
  border-radius: 0 0 10px 10px;
}

.InactiveOrderDetailsItem__loading {
  border-left: 1px solid #a1b4c4;
  border-right: 1px solid #a1b4c4;
}

.orderItem__item-amount {
  text-align: right;
  margin-right: 20px;
}

@media (min-width: 1023px) {
  .InactiveOrderDetails__item {
    grid-template-columns: 49px 58px 1fr 1fr 178px;
  }
}
