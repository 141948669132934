.SuperAdminHolidaysListItem {
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #a1b4c4;
}

.SuperAdminHolidaysListItem:last-child() {
  border-bottom: none;
}

.SuperAdminHolidaysList {
  border: 1px solid #a1b4c4;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.SuperAdminHolidaysListItem__date {
  margin-right: 20px;
}

.SuperAdminHolidaysListItem__date-text {
  color: #6d7a85;
  opacity: 0.8;
}

.SuperAdminHolidaysListItem__day-name {
  color: rgb(12, 44, 82);
  font-weight: 700;
}

.SuperAdminHolidaysListItem__edit {
  justify-self: flex-end;
  margin-left: auto;
  cursor: pointer;
}

.SuperAdminHolidaysListItem__remove {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.SuperAdminHolidaysListItem__link {
  padding: 10px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: #5782b3;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.SuperAdminHolidaysListItem__link:hover {
  background-color: rgba(114, 166, 213, 0.25);
}
