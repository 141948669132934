.ScanCard__flip-container {
  height: 100%;
  perspective: 1000px;
  box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: visible;
}

.ScanCard__flip-container-activated {
  transform: rotateY(180deg);
}

.ScanCard__flipper {
  height: 100%;
  transition: 1.2s;
  transform-style: preserve-3d;
  transform-origin: 50%;
  position: relative;
}

.ScanCard__front {
  height: 100%;
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

.ScanCard__front,
.ScanCard__back {
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.ScanCard__back {
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
}

.ScanCard {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  outline: none;
}

.ScanCard__image {
  padding: 20px 52px 20px 53px;
  border-bottom: 1px solid #d8d8d8;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.ScanCard__image > img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  margin: auto;
}

.ScanCard__delete-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ScanCard__delete-product {
  position: absolute;
  top: 26px;
  right: 28px;
  border: 1px solid #a1b4c4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all 0.1s linear;
}

.ScanCard__delete-product:hover {
  background-color: rgba(161, 180, 196, 0.4);
  transition: all 0.1s linear;
  cursor: pointer;
}

.ScanCard__content {
  padding: 0 30px;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ScanCard__title--text {
  font-size: 25px;
  font-weight: bold;
  color: #0c2c52;
  line-height: 1.25em;
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
}

.ScanCard__details {
  padding-top: 1em;
  color: #686868;
  font-size: 14px;
  line-height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ScanCard__id {
  color: #686868;
  font-size: 14px;
  line-height: 19px;
}

.ScanCard__stockIndicator {
  padding-top: 15px;
}

.ScanCard__stockIndicator__text {
  color: #686868;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  display: inline-block;
}

.ScanCard__stockIndicator__inventoryAmount {
  float: right;
}

.ScanCard__stockIndicator__bar {
  height: 14px;
  margin-top: 9px;
}

.ScanCard__actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

@media (1025px > width) {
  .ScanCard__title--text {
    font-size: 20px;
  }

  .ScanCard__details {
    padding-top: 0.5em;
  }
}
