.SettingsContainer {
  background-color: #0c2c52;
  height: 55px;
  color: #a1b4c4;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.SettingsContainer__Header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.SettingsContainer__Header__Text {
  margin-left: 27px;
}
