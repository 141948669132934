.ItemSearchBarResults {
  position: absolute;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  z-index: 40;
  max-height: 333px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 40px;
}

.ItemSearchBarResult {
  border-bottom: 1px solid #d8d8d8;
  height: 66px;
  padding: 6px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.ItemSearchBarResult * {
  pointer-events: none;
}

.ItemSearchBarResult:hover {
  background-color: rgba(64, 146, 215, 0.2);
}

.ItemSearchBarResult:hover .ItemSearchBarResults__overlay {
  opacity: 1;
}

.ItemSearchBarResults__no-items {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ItemSearchBarResults__no-items-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 2px;
  line-height: 18px;
}

.ItemSearchBarResult__image {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  background: transparent;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  flex-shrink: 0;
}

.ItemSearchBarResult__image--added {
  opacity: 0.5;
}

.ItemSearchBarResult__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ItemSearchBarResult__content {
  display: flex;
  flex-direction: column;
  min-width: 0px;
}

.ItemSearchBarResult__name {
  color: #0c2c52;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 2px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ItemSearchBarResult__details {
  line-height: 17px;
  color: #6d7a85;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ItemSearchBarResults__overlay {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 210px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.9) 46.32%,
    rgba(255, 255, 255, 0) 103.79%
  );
}

.ItemSearchBarResults__pick-me {
  justify-self: flex-end;
  margin-left: auto;
  opacity: 0;
  font-size: 12px;
  color: #4092d7;
  text-align: right;
  margin-right: 20px;
  display: flex;
}

.ItemSearchBarResult:hover .ItemSearchBarResults__pick-me {
  opacity: 1;
}

.ItemSearchBarResult__content--grayed {
  opacity: 0.6;
}

.ItemSearchBarResults__created {
  color: #53a682;
  padding-left: 3px;
}

.ItemSearchBarResults__exists {
  opacity: 1;
  justify-self: flex-end;
  font-size: 12px;
  text-align: right;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.ItemSearchBarResults__exists > .Icon {
  margin-left: auto;
}

.ItemSearchBarResult__foldout {
  height: 100%;
  transform: translateX(-5px);
  width: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ItemSearchBarResult__foldout-content > div > svg > path {
  fill: #0c2c52;
}

.ItemSearchBarResult__status {
  flex: 1;
}
