.BoxLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  position: relative;
}

.BoxLoader__border {
  position: absolute;
  transform: translateY(-9px);
}

.sk-cube-grid {
  width: 70px;
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 11px;
  margin-top: 16px;
}

.sk-cube-grid .sk-cube {
  width: 27%;
  height: 27%;
  /* background-color: #a1b4c4; */
  margin: 1px 2px;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube2 {
  background-color: transparent !important;
}

.sk-cube3 {
  background-color: transparent !important;
}

.sk-cube6 {
  background-color: transparent !important;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.BoxLoader__text {
  font-size: 10px;
  font-weight: 700;
  color: #6b8294;
  line-height: 12px;
  text-align: center;
  height: 12px;
}

/* Light theme */
.BoxLoader__border--light {
  color: #a1b4c4;
}

.BoxLoader__border--light > div > svg > path {
  fill: #a1b4c4;
}

.sk-cube-grid--light .sk-cube {
  background-color: #a1b4c4;
}

/* Dark theme */
.BoxLoader__border--dark {
  color: rgba(107, 130, 148, 0.8);
}

.BoxLoader__border--dark > div > svg > path {
  fill: rgba(107, 130, 148, 0.8);
}

.sk-cube-grid--dark .sk-cube {
  background-color: rgba(107, 130, 148, 0.8);
}
