.OrderItemContainer {
  display: grid;
  grid-template-columns: 40px 5ch 1fr 1fr 155px 180px 46px;
  height: 55px;
  align-items: center;
  gap: 10px;
}

.OrderItemContainer:last-child {
  border-radius: 0 0 10px 10px;
}

.OrderItemContainer--active {
  background-color: #fff;
}

.OrderItemContainer--backorder {
  background-color: rgba(255, 255, 255, 0.85);
}

.OrderItemContainer--completed {
  background-color: rgba(255, 255, 255, 0.85);
}

.OrderItemContainer--completed > .OrderItem__item-name {
  opacity: 0.6;
}

.OrderItemContainer--completed > .OrderItem__item-description {
  opacity: 0.6;
}

@media screen and (max-width: 820px) {
  .OrderItemContainer {
    grid-template-columns: 0px 5ch 1fr 0px minmax(0px, max-content) 180px 46px;
  }

  .OrderItemContainer > .OrderItem__item-view-tooltip {
    overflow: hidden;
  }

  .OrderItemContainer > .OrderItem__item-description {
    overflow: hidden;
  }
}
