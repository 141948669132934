.ScanCardActions {
  display: flex;
  width: 100%;
  text-align: center;
  border-radius: 0 0 10px 10px;
  height: 97px;
  border-top: 1px solid #d8d8d8;
}

.ScanCardActions__action {
  display: inline-block;
  width: 37.5%;
  font-size: 25px;
  font-weight: bold;
  height: 100%;
  position: relative;
}

.ScanCardActions__takeMore {
  border-radius: 0 0 0 10px;
  background-color: rgba(170, 37, 37, 0.4);
  color: #aa2525;
  transition: all 250ms linear;
}

.ScanCardActions__takeMore:hover {
  background-color: rgba(170, 37, 37, 0.48);
  transition: all 250ms linear;
}

.ScanCardActions__takeLess {
  border-radius: 0 0 10px 0;
  background-color: rgba(161, 180, 196, 0.1);
  color: #a1b4c4;
  transition: all 250ms linear;
}

.ScanCardActions__takeLess:hover {
  background-color: rgba(161, 180, 196, 0.18);
  transition: all 250ms linear;
}

.ScanCardActions__amount {
  position: relative;
  background-color: rgba(170, 37, 37, 0.2);
  display: inline-block;
  /* width: 25%; */
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  height: 100%;
  color: #aa2525;
  transition: width 200ms linear;
}

.ScanCardActions__icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScanCardActions__takeMore-icon > div {
  height: 40%;
}

.ScanCardActions__amount-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
