.ShimmerBar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.ShimmerBar--dark {
  background-color: rgba(161, 180, 196, 0.4);
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.ShimmerBar__bar {
  border-radius: 4px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: slide;
  animation-timing-function: linear;
  width: 100%;
  height: 100%;
  position: relative;
}

.ShimmerBar__bar--light {
  border-radius: 4px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}

.ShimmerBar__bar--dark {
  border-radius: 4px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
}
