.ExitButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ExitButton__text {
  color: #6D7A85;
  font-size: 12px;
  font-weight: bold;
  opacity: .7;
  margin-right: 7px;
}

.ExitButton__icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(109, 122, 133, .3);
  position: relative;
  transition: all 200ms ease-in-out;
}

.ExitButton__icon:hover {
  border: 1px solid rgba(109, 122, 133, 1);
  transition: all 200ms ease-in-out;
}

.ExitButton__icon > div {
  opacity: 1;
}