.AddCommentToActiveOrder {
  padding: 0 95px;
}

.AddCommentToActiveOrder__header {
  padding-top: 109px;
  padding-bottom: 45px;
}

.AddCommentToActiveOrder__title-text {
  color: #0c2c52;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.AddCommentToActiveOrderFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.AddCommentToActiveOrderFooter__footer-changesSaved {
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
  width: 300px;
  justify-content: flex-end;
}
