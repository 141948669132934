.DashboardInventoryValueChart {
  height: 500px;
  /* margin: 40px 60px 40px 15px; */
  margin: 40px 0px 40px 0px;
  margin-left: -20px;
}

.DashboardInventoryValueChart__loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* RECHARTS CSS OVERRIDES */
.recharts-bar-rectangle > path {
  border-radius: 4px 4px 0 0;
}

.DashboardInventoryValueChart__xaxis-label {
  font-size: 11px;
}

.DashboardCustomizedTooltip {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #a1b4c4;
  padding: 14px 16px 6px 16px;
  /* width: 230px; */
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.DashboardCustomizedTooltip__title {
  margin-bottom: 8px;
}

.DashboardCustomizedTooltip__title-text {
  /* border-bottom: 1px solid #a1b4c4; */
  font-weight: 700;
}

.DashboardCustomizedTooltip__content--inner {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.DashboardCustomizedTooltip__color-box {
  height: 15px;
  width: 15px;
  margin-right: 3px;
}

.DashboardCustomizedTooltip__name {
  color: #0c2c52;
  font-size: 15px;
  margin-right: 20px;
}

.DashboardCustomizedTooltip__value {
  justify-self: flex-end;
  margin-left: auto;
  color: #6d7a85;
  font-size: 15px;
}
