.DashboardInventoryValue {
  width: 100%;
  display: flex;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.DashboardInventoryValue__selector {
  position: absolute;
  right: 0;
}

.DashboardInventoryValue__chart {
  border-radius: 10px 0 0 10px;
  padding: 25px 23px;
  background-color: #fff;
  flex: 2;
  position: relative;
}

.DashboardInventoryValue__information {
  flex: 1;
  background-color: #0c2c52;
  border-radius: 0 10px 10px 0;
  color: #fff;
}

.DashboardInventoryValue__title {
  margin-bottom: 20px;
  color: #0c2c52;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
}
