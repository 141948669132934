.WatchedActionModal__backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WatchedActionModal {
  width: 666px;
  /* height: 605px; */
  z-index: 710;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WatchedActionModal__close-button {
  position: absolute;
  top: 21px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.WatchedActionModal__close-text {
  opacity: 0.7;
  color: #6d7a85;
  font-weight: 700;
  font-size: 12px;
}

.WatchedActionModal__content {
  padding: 57px 92px 0 94px;
  height: 100%;
  width: 100%;
}

.WatchedActionModal__product {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.WatchedActionModal__product-image {
  width: 72px;
  height: 72px;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WatchedActionModal__product-text {
  margin-left: 12px;
}

.WatchedActionModal__product-text-title {
  color: #0c2c52;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}

.WatchedActionModal__product-text-prodno {
  color: #6d7a85;
  font-size: 13px;
  line-height: 19px;
}

.WatchedActionModal__button {
  justify-self: flex-end;
  height: 85px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: #4092d7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.WatchedActionModal__button:hover {
  background-color: #3082c5;
}

.WatchedActionModal__button-text {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
}

.WatchedActionModal__button > div > div > svg > g > g > circle path {
  fill: #fff;
}

.WatchedActionBox {
  border-radius: 4px;
  background-color: #eef7ff;
  border: 1px solid #4092d7;
  width: 100%;
  display: flex;
  padding: 34px 59px 29px 85px;
  position: relative;
}

.WatchedActionBox__text {
  font-size: 14px;
  line-height: 18px;
  color: #3e7db2;
  transform: translateY(-4px);
}

/* WatchedRecommendationBox */
.WatchedRecommendationBox {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.WatchedRecommendationBox__titles {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: auto;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 12px;
}

.WatchedRecommendationBox__titles-title {
  opacity: 0.7;
  font-size: 11px;
  line-height: 12px;
  text-align: right;
  color: #6d7a85;
}

.WatchedRecommendationBox__titles-current {
  margin-right: 85px;
}

.WatchedRecommendationBox__ignore {
  justify-self: flex-end;
  text-align: right;
  margin-left: auto;
  margin-top: 13px;
  cursor: pointer;
}

.WatchedRecommendationBox__ignore-text {
  color: #a1b4c4;
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
  transition: all 200ms ease-in-out;
}

.WatchedRecommendationBox__ignore-text:hover {
  color: rgba(64, 146, 215, 0.8);
}

/* WatchedRecommendationBar */
.WatchedRecommendationBar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  height: 53px;
}

.WatchedRecommendationBar__title {
  color: #0c2c52;
  font-size: 15px;
  font-size: 700;
  line-height: 18px;
}

.WatchedRecommendationBar__right-side {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  margin-left: auto;
}

.WatchedRecommendationBar__input {
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: #f2f2f2;
  width: 78px;
  height: 37px;
}

.WatchedRecommendationBar__input-recommended {
  border: 1px solid #53a682;
  background-color: #def7ec;
}

.WatchedRecommendationBar__input-input {
  border-radius: 4px;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  text-align: right;
  padding-right: 22px;
  font-size: 15px;
  line-height: 17px;
  color: #6d7a85;
}

.WatchedRecommendationBar__input-recommended
  > .WatchedRecommendationBar__input-input {
  color: #53a682;
}

.WatchedRecommendationBar__diff {
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  color: #53a682;
  width: 66px;
  text-align: center;
}

/* WatchedActivateBox */
.WatchedActivateBox {
  margin-top: 55px;
  height: 53px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  position: relative;
}

.WatchedActivateBox__label {
  position: absolute;
  right: 0;
  top: -24px;
}

.WatchedActivateBox__label-text {
  opacity: 0.7;
  font-size: 11px;
  color: #6d7a85;
}

.WatchedActionBox__text-box {
  display: flex;
}

.WatchedActionBox__icon {
  position: absolute;
  width: 46px;
  height: 46px;
  background-color: #4092d7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  left: 26px;
}

.WatchedActionBox__icon > div > svg > path {
  fill: #fff;
}

.WatchedActionBox__icon > div {
  transform: translate(3px, 1px);
}
