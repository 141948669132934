.DashboardProductWatch {
  background-color: #fff;
  height: 417px;
  position: relative;
  border: 1px solid #a1b4c4;
  border-radius: 10px;
}

.DashboardProductWatch__title {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 18px;
  height: 69px; /* Nice.. */
  border-bottom: 1px solid #a1b4c4;
}

.DashboardProductWatch__title-text {
  color: #0c2c52;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.DashboardProductWatch__icon {
  justify-self: flex-end;
  margin-left: auto;
}

.DashboardProductWatch__items {
  height: 300px;
  background-color: rgba(161, 180, 196, 0.1);
  overflow: hidden;
  border-bottom: 1px solid #a1b4c4;
}

.DashboardProductWatch__more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
}

/* MORE DOT */
.MoreDot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: rgba(161, 180, 196, 0.5);
  margin-right: 5px;
}

.MoreDot__active {
  background-color: #4092d7;
}

.MoreDot__target {
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
