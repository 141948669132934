.ListItem {
  display: flex;
  align-items: center;
  width: 100%;
  color: #0c2c52;
}

.ListItem__title {
  justify-self: flex-start;
  font-size: 15px;
  font-weight: bold;
  transform: translateY(-1px);
}
