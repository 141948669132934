.ItemOrdered {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateY(2px);
}

.ItemOrdered__text {
  color: #68cea2;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  padding-left: 5px;
  transform: translateY(-2px);
}
