.Receive {
  height: 100%;
}

.Receive__header {
  /* border-bottom: 1px solid #A1B4C4; */
  position: absolute;
  top: -57px;
  width: 100%;
  vertical-align: top;
  margin-left: 38px;
  z-index: 3;
}

.Receive__dividerbar {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #a1b4c4;
  background-color: #a1b4c4;
  margin-left: 38px;
}

.Receive__title {
  color: #0c2c52;
  font-size: 25px;
  font-weight: bold;
  display: inline-block;
  padding-right: 254px;
  padding-bottom: 24px;
}

.Receive__export {
  vertical-align: middle;
  position: absolute;
  right: 0px;
  height: 50px;
  width: calc(100% - 10px);
  z-index: 2;
  margin-right: 10px;
  transform: translateY(-8px);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.Receive__export-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(161, 180, 196, 0.4);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Receive__export-backdrop > .ExportComponent {
  margin-right: 28px;
}

.Receive__content {
  padding: 20px 28px 30px 38px;
  position: relative;
}

.Receive__content::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(109, 122, 133, 0.3);
  position: fixed;
  left: 0;
}

.Receive__content::-webkit-scrollbar-thumb {
  background-color: rgb(109, 122, 133, 0.5);
}

.Receive__subtitle {
  color: #0c2c52;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 9px;
}

.Receive__subtitle-active {
  padding-top: 10px;
}

.Receive__subtitle-incoming {
  padding-top: 20px;
}

.Receive__show-earlier {
  max-width: 190px;
  margin: 34px auto 14px auto;
}

.Receive__noorder {
  padding: 10px 0;
}

.Receive__no-orders {
  color: #0c2c52;
  opacity: 0.6;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.Receive__loading {
  margin: 120px 28px 30px 38px;
  height: 348px;
  display: flex;
  flex-direction: column;
}

.Receive__completedOrders {
  padding-top: 20px;
}

.Receive__completedOrders__title-text {
  color: #0c2c52;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding-bottom: 9px;
}

.Receive__no-current-orders {
  height: 55px;
  text-align: center;
  width: 100%;
  color: #0c2c52;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #a1b4c4;
  line-height: 50px;
}
