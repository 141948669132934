.TextArea {
  width: 100%;
}

/* Class to reset all the annoyances textareas have in the browser */
.TextArea__reset {
  outline: none;
  border: none;
  box-sizing: border-box;
}

.TextArea__default {
  width: 100%;
  height: 100%;
  border: 1px solid #a1b4c4;
  border-radius: 4px;
  background-color: rgba(161, 180, 196, 0.2);
  padding: 9px;
  font-size: 15px;
  line-height: 17px;
  color: #0c2c52;
}

/* .TextArea__default:hover {
  background-color: #fff;
}

.TextArea__default:focus {
  background-color: #fff;
} */

.TextArea__order-comment {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #a1b4c4;
  /* border-radius: 4px; */
  background-color: transparent;
  padding: 9px;
  font-size: 15px;
  line-height: 17px;
  color: #0c2c52;
  transition: all 300ms ease-in-out;
}

.TextArea__order-comment:last-child {
  border-bottom: none;
}
