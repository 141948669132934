.DashboardFiveItemList {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.DashboardFiveItemListItem {
  display: flex;
  flex-direction: row;
}

.DashboardFiveItemListItem__content {
  display: flex;
  flex-direction: column;
}

.DashboardFiveItemListItem__title {
  font-size: 15px;
  font-weight: 700;
  color: #0c2c52;
  line-height: 18px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DashboardFiveItemListItem__values {
  display: flex;
  flex-direction: row;
  margin-left: 18px;
}

.DashboardFiveItemListItem__values-value {
  color: #6d7a85;
  font-size: 15px;
  line-height: 17px;
}

.DashboardFiveItemListItem__values-change {
  display: flex;
  flex-direction: row;
  transform: translateY(2px);
}

.DashboardFiveItemListItem__values-icon {
  margin-left: 9px;
  position: relative;
}
.DashboardFiveItemListItem__values-icon > div {
  position: absolute;
  top: -4px;
}

.DashboardFiveItemListItem__values-change-positive > div > div > svg > path {
  fill: #53a682;
  opacity: 1;
}
.DashboardFiveItemListItem__values-change-positive
  > .DashboardFiveItemListItem__values-change-value {
  color: #53a682;
}

.DashboardFiveItemListItem__values-change-negative
  > .DashboardFiveItemListItem__values-change-value {
  color: #aa2525;
}

.DashboardFiveItemListItem__values-change-value {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: -0.11px;
  margin-left: 14px;
}
