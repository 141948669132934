.ItemHistory {
  color: #0c2c52;
  margin-top: 45px;
}

.ItemHistory__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;
}

.ItemHistory__history {
  border-top: 1px solid #d8d8d8;
}

.ItemHistory__history--show-more {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
