.AutomaticOrderToggleButton {
  width: 75%;
  height: 14px;
  cursor: pointer;
  transform: translateY(-1px);
}

.AutomaticOrderToggleButton__button {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.AutomaticOrderToggleButton__icon-housing {
  border-radius: 3px;
  padding: 5px 9px;
  position: absolute;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}
