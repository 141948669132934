.ScanItemInModalAmountErrorItem {
  height: 53px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
}

.ScanItemInModalAmountErrorItem__isError {
  background-color: rgba(170, 37, 37, 0.1);
  color: #aa2525;
}

.ScanItemInModalAmountErrorItem__itemDetails {
  margin-left: 10px;
}

.ScanItemInModalAmountErrorItem__name {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
}

.ScanItemInModalAmountErrorItem__id {
  font-size: 11px;
  margin-top: 2px;
}

.ScanItemInModalAmountErrorItem__adjustments {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.ScanItemInModalAmountErrorItem__amountOrdered {
  display: flex;
}

.ScanItemInModalAmountErrorItem__amountOrdered-amount {
  color: #0c2c52;
  font-size: 15px;
  text-align: right;
}

.ScanItemInModalAmountErrorItem__amountOrdered-unit {
  opacity: 0.4;
  color: #0c2c52;
  font-size: 15px;
  margin-left: 8px;
}

.ScanItemInModalAmountErrorItem__amountReceived {
  margin-left: 50px;
}

.ScanItemInModalAmountErrorItem__deleteMe {
  margin-right: 13px;
  margin-left: 20px;
}

.ScanItemInModalAmountErrorItem__notInOrder {
  display: flex;
  align-items: center;
}

.ScanItemInModalAmountErrorItem__notInOrder-icon circle {
  fill: #aa2525;
}

.ScanItemInModalAmountErrorItem__notInOrder-text {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
}
